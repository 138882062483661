import "./HomePage.css";
import React from "react";
import { Link } from "react-router-dom";

const HomePage = ({ staff }) => {
  return (
    <div className="home-page flex">
      <div className="home-links-grid">
        <Link className="home-page-link tech flex" to="/tech">
          Tech
        </Link>
        <Link className="home-page-link grow flex" to="/grow">
          GROW
        </Link>
        {staff && (
          <Link className="home-page-link suites flex" to="/suites">
            Suites
          </Link>
        )}
      </div>
    </div>
  );
};

export default HomePage;
