import "./UpdateFounderForm.css";
import { useEffect, useState } from "react";
import {
  TextInput,
  SelectInput,
  createOptions,
} from "../../FormComponents/FormInputs";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams, useHistory } from "react-router-dom";

export default function UpdateFounderForm() {
  const { id } = useParams();
  const history = useHistory();
  const [founder, setFounder] = useState(null);
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companiesDictionary, setCompaniesDictionary] = useState(null);

  useEffect(() => {
    async function getData() {
      const founderRes = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/founders/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const founderData = await founderRes.json();
      if (founderRes.status === 200) {
        setFounder(founderData);

        const companyRes = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/companies/${founderData.company_id}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const companyData = await companyRes.json();
        if (companyRes.status === 200) {
          setCompany(companyData);
        }
      }

      const companiesRes = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/companies`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      const companiesData = await companiesRes.json();
      if (companiesRes.status === 200) {
        setCompanies(companiesData);
        const companiesDictionaryObj = Object();

        companiesData.forEach((c) => {
          companiesDictionaryObj[c.name] = c.id;
        });
        setCompaniesDictionary(companiesDictionaryObj);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (founder && companies) {
      const companyInput = document.getElementById("company-id");
      createOptions(companyInput, companiesDictionary, [founder.company_id]);
    }
  }, [companiesDictionary]);

  function hideSuccess() {
    document.getElementById("add-success").style.display = "none";
    history.goBack();
  }

  function hideFail() {
    document.getElementById("add-fail").style.display = "none";
  }

  function handleCancel() {
    history.goBack();
  }

  return (
    <>
      {founder && (
        <div className="flex" id="update-founder-form-container">
          <Formik
            initialValues={{
              firstName: founder.first_name,
              lastName: founder.last_name,
              email: founder.email,
              phoneNum: founder.phone,
              company_id: founder.company_id,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string("Must be a string").required(),
              lastName: Yup.string("Must be a string").required(),
              email: Yup.string("Must be a string").required(),
              phoneNum: Yup.string().nullable(),
              company_id: Yup.number(),
            })}
            onSubmit={async (values, { resetForm }) => {
              const res = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/founders/${founder.id}`,
                {
                  method: "PUT",
                  credentials: "include",
                  headers: {
                    "CONTENT-TYPE": "application/json",
                  },
                  body: JSON.stringify({
                    first_name: values.firstName,
                    last_name: values.lastName,
                    email: values.email,
                    phone: values.phoneNum,
                    company_id:
                      values.company_id === 0 ? null : values.company_id,
                  }),
                }
              );
              const data = await res.json();

              if (res.status === 200) {
                document.getElementById("success-header").innerText = `${
                  data.data.first_name + " " + data.data.last_name
                } updated`;
                document.getElementById("add-success").style.display = "flex";
                resetForm();
              } else {
                document.getElementById("add-fail").style.display = "flex";
              }
            }}
          >
            {({ values, resetForm }) => (
              <Form
                id="update-founder"
                className="founder-update-form flex-col"
              >
                <h2>Update {founder.first_name + " " + founder.last_name}</h2>
                <TextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                />
                <TextInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
                <TextInput
                  label="Email"
                  name="email"
                  type="text"
                  placeholder="Email"
                />
                <TextInput
                  label="Phone Number"
                  name="phoneNum"
                  type="text"
                  placeholder="(---) --- ----"
                />
                <SelectInput
                  label="Company"
                  name="company_id"
                  type="number"
                  id="company-id"
                >
                  <option value={0}>Select a Company</option>
                </SelectInput>
                <div className="form-buttons flex">
                  <button type="submit" id="founder-submit">
                    Save Changes
                  </button>
                  <button
                    type="button"
                    id="founder-submit"
                    onClick={() => {
                      resetForm({
                        values: {
                          firstName: "",
                          lastName: "",
                          email: "",
                          phoneNum: "",
                          company_id: 0,
                        },
                      });
                    }}
                  >
                    Clear
                  </button>
                </div>
                <button
                  type="button"
                  id="founder-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </Form>
            )}
          </Formik>
          <div className="popup flex-col" id="add-success">
            <button className="hide-popup" onClick={hideSuccess}>
              X
            </button>
            <h2 className="popup-header" id="success-header">
              Updated Successfully
            </h2>
          </div>
          <div className="popup flex-col" id="add-fail">
            <button className="hide-popup" onClick={hideFail}>
              X
            </button>
            <h2 className="popup-header">
              Something went wrong, please try again
            </h2>
          </div>
        </div>
      )}{" "}
      {!founder && <h1>Loading...</h1>}
    </>
  );
}
