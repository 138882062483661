import "./UpdateCompanyForm.css";
import { useEffect, useState } from "react";
import {
  TextInput,
  NumberInput,
  SelectInput,
  MultiSelectInput,
  DateInput,
  getValues,
  createOptions,
} from "../../FormComponents/FormInputs";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function UpdateCompanyForm({ entity, contact, setEditing }) {
  const [sectors, setSectors] = useState([]);
  const [founderDictionary, setFounderDictionary] = useState();

  console.log(entity)

  // get all sectors covered by firms in db on page load
  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const sectors = await getValues("sectors");
      const founders = await getValues("founders");
      const founderDictionaryObj = Object();

      founders.forEach((f) => {
        const name = `${f.first_name} ${f.last_name}`;
        founderDictionaryObj[name] = f.id;
      });

      if (isMounted) {
        setSectors(sectors);
        setFounderDictionary(founderDictionaryObj);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // create an option for each sector and set initial values for sector-select input element
  // note: initialValues are for element only, not formik
  useEffect(() => {
    const sectorInput = document.getElementById("sector-select");
    if (sectors && sectorInput) {
      createOptions(sectorInput, sectors, entity.sectors);
    }
    const founderInput = document.getElementById("founder-input");
    if (founderDictionary && founderInput) {
      createOptions(founderInput, founderDictionary, [entity.founder_id]);
    }
  }, [sectors, entity.sectors, founderDictionary, entity.founder_id]);

  // set editing status to false
  // hides update form and shows company detail view on singleEntity page
  function handleCancel() {
    setEditing(false);
  }

  function hideSuccess() {
    document.getElementById("add-success").style.display = "none";
    setEditing(false);
  }

  function hideFail() {
    document.getElementById("add-fail").style.display = "none";
  }

  const defaultDate = entity.founded_date
    ? entity.founded_date.split("T")[0]
    : new Date(Date.now()).toISOString().split("T")[0];

  // console.log(entity.sectors)

  return (
    <div className="flex" id="update-company-form-container">
      <Formik
        enableReinitialize
        initialValues={{
          founderId: entity.founder_id || 1,
          sectors: entity.sectors,
          program: entity.program,
          foundedDate: defaultDate,
          raising: entity.raising,
          amount: entity.amount,
          pre: entity.pre,
          leftInRound: entity.left_in_round,
          name: entity.name,
          totalRevenue: entity.total_revenue,
          monthlyRevenue: entity.monthly_revenue,
          introdTo: entity.introd_to.join(","),
          interestFrom: entity.interest_from.join(","),
          passedBy: entity.passed_by.join(","),
          callWith: entity.call_with.join(","),
          checksFrom: entity.checks_from.join(","),
          passedByFounder: entity.passed_by_founder.join(','),
          totalRaised: entity.total_raised,
        }}
        validationSchema={Yup.object().shape({
          founderId: Yup.number("Must be a number").min(
            0,
            "Must be a positive integer"
          ),
          sectors: Yup.array().of(Yup.string()).required(),
          foundedDate: Yup.date("Must be a date").required(),
          raising: Yup.bool("Must be a bool").required(),
          amount: Yup.number("Must be a number").min(
            0,
            "Must be a positive integer"
          ),
          pre: Yup.number("Must be a number"),
          leftInRound: Yup.number("Must be a number").min(
            0,
            "Must be positive"
          ),
          name: Yup.string("Must be a string")
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          totalRevenue: Yup.number("Must be a number").min(
            0,
            "Must be positive"
          ),
          monthlyRevenue: Yup.number("Must be a number").min(
            0,
            "Must be positive"
          ),
          introdTo: Yup.string("Must be a string"),
          interestFrom: Yup.string("Must be a string"),
          passedBy: Yup.string("Must be a string"),
          passedByFounder: Yup.string("Must be a string"),
          callWith: Yup.string("Must be a string"),
          checksFrom: Yup.string("Must be a string"),
          totalRaised: Yup.number("Must be a number").min(
            0,
            "Must be positive"
          ),
        })}
        onSubmit={async (values, { resetForm }) => {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/companies/${entity.id}`,
            {
              method: "PUT",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                founder_id: parseInt(values.founderId),
                sectors: values.sectors.join(","),
                program: values.program,
                founded_date: new Date(values.foundedDate).toISOString(),
                raising: values.raising,
                amount: values.amount,
                pre: values.pre,
                left_in_round: values.leftInRound,
                name: values.name,
                total_revenue: values.totalRevenue,
                monthly_revenue: values.monthlyRevenue,
                introd_to: values.introdTo,
                interest_from: values.interestFrom,
                passed_by: values.passedBy,
                passed_by_founder: values.passedByFounder,
                call_with: values.callWith,
                checks_from: values.checksFrom,
                total_raised: values.totalRaised,
              }),
            }
          );
          const data = await res.json();

          if (res.status === 200) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("add-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("add-fail").style.display = "flex";
          }
        }}
      >
        {({ values, resetForm }) => (
          <Form id="update-company" className="update-form flex-col">
            <h2>Update {entity.name}</h2>
            <TextInput
              label="Company name"
              name="name"
              type="text"
              placeholder="Company name"
            />
            <SelectInput id="founder-input" label="Founder" name="founderId">
              <option value="">Select a founder</option>
            </SelectInput>
            {/* sectors checkbox */}
            <MultiSelectInput
              id="sector-select"
              label="Sectors"
              multiple="multiple"
              name="sectors"
            ></MultiSelectInput>
            {/* program select */}
            <SelectInput label="Company program" name="program">
              <option value="">Select an option</option>
              <option value="Incubator">Incubator</option>
              <option value="Accelerator">Accelerator</option>
              <option value="Incubator (Remote)">Incubator (Remote)</option>
              <option value="UARF">UARF</option>
            </SelectInput>
            <DateInput label="Date founded" name="foundedDate" type="date" />
            {/* raising select */}
            <SelectInput label="Currently raising" name="raising">
              <option value="">Select an option</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </SelectInput>
            {values.raising && (
              <>
                <NumberInput
                  label="Total revenue ($, thousands)"
                  name="totalRevenue"
                  type="number"
                />
                <NumberInput
                  label="Monthly revenue ($, thousands)"
                  name="monthlyRevenue"
                  type="number"
                />
                <NumberInput
                  label="Raise amount ($, thousands)"
                  name="amount"
                  type="number"
                />
                <NumberInput
                  label="Pre $ Valuation ($, thousands)"
                  name="pre"
                  type="number"
                />
                <NumberInput
                  label="Left in round ($, thousands)"
                  name="leftInRound"
                  type="number"
                />
                <TextInput
                  label="Already introduced to"
                  name="introdTo"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Interest from"
                  name="interestFrom"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Passed by"
                  name="passedBy"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Passed by Founder"
                  name="passedByFounder"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Calls with"
                  name="callWith"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Checks from"
                  name="checksFrom"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <NumberInput
                  label="Total raised"
                  name="totalRaised"
                  type="number"
                />
              </>
            )}
            <div className="form-buttons flex">
              <button type="submit">Save Changes</button>
              <button
                type="button"
                onClick={() => {
                  resetForm({
                    values: {
                      founderId: 1,
                      sectors: "",
                      foundedDate: Date.now().toLocaleString(),
                      raising: false,
                      amount: 0,
                      pre: 0,
                      leftInRound: 0,
                      name: "",
                      totalRevenue: 0,
                      monthlyRevenue: 0,
                      introdTo: "",
                      interestFrom: "",
                      passedBy: "",
                      passedByFounder: "",
                      callWith: "",
                      checksFrom: "",
                      totalRaised: 0,
                    },
                  });
                }}
              >
                Clear
              </button>
            </div>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="add-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">Updated successfully</h2>
        {/* <div className="popup-buttons flex">
          <Link className="popup-button" to="/tech/companies">
            See companies
          </Link>
          <button className="popup-button" onClick={hideSuccess}>
            Add another
          </button>
        </div> */}
      </div>
      <div className="popup flex-col" id="add-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header">Something went wrong, please try again</h2>
      </div>
    </div>
  );
}
