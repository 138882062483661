import "./CompaniesPage.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../Components/SearchBar/SearchBar";
import FilterForm from "../../Components/Filter/FilterForm";
import CompanyTable from "../../Components/Companies/CompanyTable/CompanyTable";

const CompaniesPage = () => {
  const [companies, setCompanies] = useState(null);
  // const [view, setView] = useState("all");

  async function getData() {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/companies`, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    setCompanies(data);
  }
  // get firm data from api on page load
  useEffect(() => {
    getData();
  }, []);

  function handleShowFilters() {
    document.getElementById("filters").style.display = "flex";
    document.getElementById("show-filters").style.display = "none";
    document.getElementById("hide-filters").style.display = "block";
  }

  function handleHideFilters() {
    document.getElementById("filters").style.display = "none";
    document.getElementById("hide-filters").style.display = "none";
    document.getElementById("show-filters").style.display = "block";
  }

  if (companies === null) {
    return <h2 className="loading-header">Loading companies...</h2>;
  } else if (companies === undefined) {
    return <h2>No match found</h2>;
  } else {
    return (
      <div className="page-container flex-col">
        <div id="companies-page-header-container" className="flex-col">
          <div className="search-filter flex">
            <h1 id="companies-header">Companies</h1>
            <SearchBar
              endpoint="/companies/search"
              functions={[setCompanies]}
              id="company-search"
            />
            <div id="buttons">
              <button id="show-filters" onClick={handleShowFilters}>
                Filters
              </button>
              <button id="hide-filters" onClick={handleHideFilters}>
                Hide filters
              </button>
              <Link id="add-company" to="/tech/companies/add">
                Add
              </Link>
            </div>
          </div>
          <div className="filters flex-col" id="filters">
            <h3 className="filter-header">Filter by program</h3>
            <FilterForm
              filterEndpoint="/companies/filter"
              clearEndpoint="/companies"
              valueType="programs"
              functions={[setCompanies]}
            />
          </div>
        </div>

        <CompanyTable companies={companies} />
      </div>
    );
  }
};

export default CompaniesPage;
