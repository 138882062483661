import './ResetPasswordPage.css'
import React from 'react'
import ResetPasswordForm from '../../Components/UpdateForms/ResetPassword/ResetPasswordForm'

const ResetPasswordPage = () => {
  
  return (
    <ResetPasswordForm />
  )
}

export default ResetPasswordPage