import "./DeleteUserPage.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../Components/SearchBar/SearchBar";

const DeleteUserPage = (props) => {
  const { admin } = props;
  const [user, setUser] = useState(null);

  function handleClick() {
    document.getElementById("delete-confirmation").style.display = "flex";
  }

  function hideConfirmation() {
    document.getElementById("delete-confirmation").style.display = "none";
  }

  function hideSuccess() {
    document.getElementById("delete-success").style.display = "none";
  }

  function hideFail() {
    document.getElementById("delete-fail").style.display = "none";
  }

  async function handleDelete() {
    hideConfirmation();

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "CONTENT-TYPE": "application/json",
      },
      body: JSON.stringify({
        username: user[0].username,
      }),
    });

    if (res.status === 200) {
      document.getElementById("delete-success").style.display = "flex";
    } else {
      document.getElementById("delete-fail").style.display = "flex";
    }
  }

  if (admin) {
    return (
      <div className="update-user-page-container flex-col">
        <h1>Remove User</h1>
        <SearchBar
          endpoint="/users/search"
          functions={[setUser]}
          id="user-search"
        />
        {user && (
          <div className="user-container flex-col">
            <p className="user-info">Username: {user[0].username}</p>
            <p className="user-info">
              Admin: {user[0].is_admin ? "True" : "False"}
            </p>
            <button onClick={handleClick}>Remove user</button>
            {/* confirmation popup */}
            <div className="popup flex-col" id="delete-confirmation">
              <button className="hide-popup" onClick={hideConfirmation}>
                X
              </button>
              <h2 className="popup-header" id="confirmation-header">
                Are you sure you want to delete {user[0].username}?
              </h2>
              <div className="popup-buttons flex">
                <button className="popup-button" onClick={handleDelete}>
                  Delete
                </button>
                <button className="popup-button" onClick={hideConfirmation}>
                  Cancel
                </button>
              </div>
            </div>
            {/* delete success popup */}
            <div className="popup flex-col" id="delete-success">
              <button className="hide-popup" onClick={hideSuccess}>
                X
              </button>
              <h2 className="popup-header" id="success-header">
                User {user[0].username} deleted
              </h2>
              <div className="popup-buttons flex">
                <Link className="popup-button" to="/admin">
                  Admin page
                </Link>
                <Link className="popup-button" to="/">
                  Home
                </Link>
              </div>
            </div>
            {/* delete fail popup */}
            <div className="popup flex-col" id="delete-fail">
              <button className="hide-popup" onClick={hideFail}>
                X
              </button>
              <h2 className="popup-header" id="fail-header">
                We ran into an issue, please try again
              </h2>
            </div>
          </div>
        )}
      </div>
    );
  } else if (!admin) {
    return (
      <div className="admin-page-container flex">
        <h1>You don't have permission to access this page</h1>
        <Link to="/">Home</Link>
      </div>
    );
  }
};

export default DeleteUserPage;
