import "./UpdateFirmForm.css";
import { useEffect, useState } from "react";
import {
  TextInput,
  TextArea,
  NumberInput,
  SelectInput,
  MultiSelectInput,
  DateInput,
  getValues,
  createOptions,
} from "../../FormComponents/FormInputs";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function UpdateFirmForm({
  entity,
  contact,
  editing,
  setEditing,
}) {
  const [sectors, setSectors] = useState([]);
  const [investorDictionary, setInvestorDictionary] = useState();

  // get all sectors covered by firms in db on page load
  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const sectors = await getValues("sectors");
      const investors = await getValues("investors");
      const investorDictionaryObj = Object();

      investors.forEach((i) => {
        const name = `${i.first_name} ${i.last_name}`;
        investorDictionaryObj[name] = i.id;
      });

      if (isMounted) {
        setSectors(sectors);
        setInvestorDictionary(investorDictionaryObj);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // create an option for each sector and set initial values for sector-select and blacklist-select input elements
  // note: initialValues are for element only, not formik
  useEffect(() => {
    const sectorInput = document.getElementById("sector-select");
    if (sectors && sectorInput) {
      createOptions(sectorInput, sectors, entity.sectors);
    }
    const blacklistInput = document.getElementById("blacklist-select");
    if (sectors && blacklistInput) {
      createOptions(blacklistInput, sectors, entity.blacklist);
    }
    const investorInput = document.getElementById("investor-input");
    if (investorDictionary && investorInput) {
      createOptions(investorInput, investorDictionary, [entity.investor_id]);
    }
  }, [
    sectors,
    entity.sectors,
    entity.blacklist,
    investorDictionary,
    entity.investor_id,
  ]);

  // set editing status to false
  // hides update form and shows company detail view on singleEntity page
  function handleCancel() {
    setEditing(false);
  }

  function hideSuccess() {
    document.getElementById("update-success").style.display = "none";
    setEditing(false);
  }

  function hideFail() {
    document.getElementById("update-fail").style.display = "none";
  }

  const defaultDate = entity.met_date
    ? entity.met_date.split("T")[0]
    : new Date(Date.now()).toISOString().split("T")[0];

  return (
    <div className="flex" id="update-firm-form-container">
      <Formik
        initialValues={{
          name: entity.name,
          website: entity.website,
          location: entity.location,
          notes: entity.notes,
          relationship: entity.relationship.toLowerCase(),
          metDate: defaultDate,
          minCheck: entity.min_check,
          maxCheck: entity.max_check,
          prefersLead: entity.prefers_lead,
          stages: entity.stages,
          sectors: entity.sectors,
          blacklist: entity.blacklist,
          needRev: entity.need_rev,
          prefersEarlyIntros: entity.prefers_early_intros,
          specialRequirements: entity.special_requirements,
          ownershipTargetLow: entity.ownership_target_low,
          ownershipTargetHigh: entity.ownership_target_high,
          investorId: entity.investor_id,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          website: Yup.string().url("Invalid URL format"),
          location: Yup.string(),
          notes: Yup.string().nullable(),
          relationship: Yup.string()
            .oneOf(["met", "warm"], 'Must be "Met" or "Warm"')
            .required("Required"),
          metDate: Yup.date().required("Required"),
          minCheck: Yup.number()
            .min(0, "Must be positive")
            .max(1000, "Enter value in thousands (10,000 = 10)"),
          maxCheck: Yup.number()
            .min(10, "Must be > 10")
            .max(100000, "Enter value in thousands (10,000 = 10)"),
          prefersLead: Yup.bool().nullable(),
          stages: Yup.array()
            .min(1, "You must choose at least 1 stage")
            .of(Yup.string())
            .required(),
          sectors: Yup.array()
            .min(1, "You must choose at least 1 sector")
            .of(Yup.string())
            .required(),
          blacklist: Yup.array().of(Yup.string()).required(),
          needRev: Yup.bool(),
          prefersEarlyIntros: Yup.bool().nullable(),
          specialRequirements: Yup.string().nullable(),
          ownershipTargetLow: Yup.number()
            .max(20, "Enter value as an integer from 1-20 (5% = 5)")
            .min(1, "Enter value as an integer from 1-20 (5% = 5)")
            .nullable(),
          ownershipTargetHigh: Yup.number()
            .max(20, "Enter value as an integer from 2-20 (5% = 5)")
            .min(2, "Enter value as an integer from 2-20 (5% = 5)")
            .nullable(),
          investorId: Yup.number(),
        })}
        onSubmit={async (values, { resetForm }) => {
          // console.log(JSON.stringify(values));
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/firms/${entity.id}`,
            {
              method: "PUT",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                name: values.name,
                website: values.website,
                location: values.location,
                notes: values.notes,
                relationship: values.relationship,
                met_date: values.metDate,
                min_check: values.minCheck,
                max_check: values.maxCheck,
                prefers_lead: values.prefersLead,
                stages: values.stages.join(","),
                sectors: values.sectors.join(","),
                blacklist: values.blacklist.join(","),
                need_rev: values.needRev,
                prefers_early_intros: values.prefersEarlyIntros,
                special_requirements: values.specialRequirements,
                ownership_target_low: values.ownershipTargetLow,
                ownership_target_high: values.ownershipTargetHigh,
                investor_id: parseInt(values.investorId),
              }),
            }
          );
          const data = await res.json();

          if (res.status === 200) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("update-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("update-fail").style.display = "flex";
          }
        }}
      >
        {({ values, resetForm }) => (
          <Form id="update-firm" className="update-form flex-col">
            <h2>Update {entity.name}</h2>
            <div className="inputs flex-col">
              <TextInput
                label="Firm Name"
                name="name"
                type="text"
                placeholder="Firm name"
              />
              <TextInput
                label="Website"
                name="website"
                type="text"
                placeholder="Website"
              />
              <TextInput
                label="Location"
                name="location"
                type="text"
                placeholder="Location (e.g. Los Angeles, CA)"
              />
              <TextArea
                label="Notes"
                name="notes"
                type="text"
                placeholder="Notes"
                rows="8"
                columns="40"
              />
              <SelectInput label="Relationship" name="relationship">
                <option value="">Select a relationship</option>
                <option value="met">Met</option>
                <option value="warm">Warm</option>
              </SelectInput>
              <DateInput label="Date met" name="metDate" type="date" />
              <NumberInput
                label="Minimum check size ($ thousands)"
                name="minCheck"
                type="number"
                placeholder="$100,000 = 100"
              />
              <NumberInput
                label="Maximum check size ($ thousands)"
                name="maxCheck"
                type="number"
                placeholder="$100,000 = 100"
              />
              <SelectInput label="Prefers to lead" name="prefersLead">
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              {/* stages checkbox */}
              <MultiSelectInput
                name="stages"
                label="Stages"
                multiple="multiple"
                id="stage-select"
              >
                <option name="stages" value="Pre-seed">
                  Pre-seed
                </option>
                <option name="stages" value="Seed">
                  Seed
                </option>
                <option name="stages" value="A">
                  A
                </option>
                <option name="stages" value="B">
                  B
                </option>
                <option name="stages" value="C">
                  C
                </option>
              </MultiSelectInput>
              <MultiSelectInput
                name="sectors"
                label="Sectors"
                multiple="multiple"
                id="sector-select"
              ></MultiSelectInput>
              {/* blacklist checkbox, need to refactor to FieldArray */}
              <MultiSelectInput
                name="blacklist"
                label="Blacklist"
                multiple="multiple"
                id="blacklist-select"
              ></MultiSelectInput>
              <SelectInput label="Requires revenue" name="needRev">
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              <SelectInput
                label="Prefers early intros"
                name="prefersEarlyIntros"
              >
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              <TextInput
                label="Special requirements"
                name="specialRequirements"
                type="text"
                placeholder="Special requirements"
              />
              <NumberInput
                label="Target ownership % (low)"
                name="ownershipTargetLow"
                type="number"
                placeholder="3% = 3"
              />
              <NumberInput
                label="Target ownership % (high)"
                name="ownershipTargetHigh"
                type="number"
                placeholder="3% = 3"
              />
              <SelectInput
                id="investor-input"
                label="Investor"
                name="investorId"
              >
                <option value="">Select an investor</option>
              </SelectInput>
            </div>
            <div className="form-buttons flex">
              <button type="submit">Save Changes</button>
              <button
                type="button"
                onClick={() => {
                  resetForm({
                    values: {
                      name: "",
                      website: "",
                      location: "",
                      notes: "",
                      relationship: "",
                      metDate: new Date(Date.now()).toISOString().split("T")[0],
                      minCheck: 0,
                      maxCheck: 0,
                      prefersLead: false,
                      stages: "",
                      sectors: [],
                      blacklist: [],
                      needRev: false,
                      prefersEarlyIntros: false,
                      specialRequirements: "",
                      ownershipTargetLow: 0,
                      ownershipTargetHigh: 0,
                      investorId: 1,
                    },
                  });
                }}
              >
                Clear
              </button>
            </div>
            <button id="cancel" type="button" onClick={handleCancel}>
              Cancel
            </button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="update-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          Updated successfully
        </h2>
      </div>
      <div className="popup flex-col" id="update-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          There was an issue
        </h2>
      </div>
    </div>
  );
}
