import "./FirmDetails.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Bubble from "../../EntityInfoComponents/Bubble/Bubble.js";
import CalloutCard from "../../EntityInfoComponents/CalloutCard/CalloutCard";
import ContactCard from "../../EntityInfoComponents/ContactCard/ContactCard";
import BubbleCard from "../../EntityInfoComponents/BubbleCard/BubbleCard";
import AddInvestorForm from "../../AddForms/Investor/AddInvestorForm";
import UpdateFirmNotes from "../../UpdateForms/FirmNotes/UpdateFirmNotes";
import UpdateInvestorForm from "../../UpdateForms/Investor/UpdateInvestorForm";

export default function FirmDetails({
  entity,
  setEntity,
  contact,
  setEditing,
  admin,
  prospect,
}) {
  const history = useHistory();
  const knownInvestorRelationships = ["met", "warm", "archived"];

  function handleEdit() {
    setEditing(true);
  }

  function confirmDelete() {
    document.getElementById("confirm-delete").style.display = "flex";
  }

  async function handleDelete() {
    hideConfirmDeletePopup();

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/firms/${entity.id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
    const data = await res.json();

    if (res.status === 200) {
      document.getElementById("success-header").innerText = data.message;
      document.getElementById("success").style.display = "flex";
    } else {
      document.getElementById("fail-header").innerText = data.message;
      document.getElementById("fail").style.display = "flex";
    }
  }

  const hideConfirmDeletePopup = () =>
    (document.getElementById("confirm-delete").style.display = "none");

  function confirmArchive() {
    document.getElementById("confirm-archive").style.display = "flex";
  }

  async function handleArchive() {
    hideConfirmArchivePopup();

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/firms/archive/${entity.id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      document.getElementById(
        "success-header"
      ).innerText = `${entity.name} archived`;
      document.getElementById("success").style.display = "flex";
    } else {
      document.getElementById("fail-header").innerText = data.message;
      document.getElementById("fail").style.display = "flex";
    }
  }

  function hideConfirmArchivePopup() {
    document.getElementById("confirm-archive").style.display = "none";
  }

  const hideSuccess = () => {
    document.getElementById("success").style.display = "none";
    history.push("/tech/investors");
  };

  const hideFail = () =>
    (document.getElementById("fail").style.display = "none");

  return (
    <div className="investor-container flex-col">
      <div className="flex" id="firm-details-header-container">
        <span
          className="material-symbols-outlined"
          id="back-button"
          onClick={() => history.goBack()}
        >
          arrow_back
        </span>
        <h1 className="investor-name">
          {entity.name}{" "}
          <a href={entity.website} target="_blank" rel="noreferrer">
            <span className="material-symbols-outlined">open_in_new</span>
          </a>
        </h1>
      </div>

      <section className="stage-bubbles flex">
        {entity.stages.map((s, i) => {
          return <Bubble bubbleText={s} type="highlight" key={i} />;
        })}
      </section>
      <section className="sector-bubbles flex">
        {entity.sectors.map((s, i) => {
          return <Bubble bubbleText={s} type="normal" key={i} />;
        })}
      </section>
      {!knownInvestorRelationships.includes(entity.relationship) && (
        <section className="relationship-bubble flex">
          <Bubble bubbleText={entity.relationship} type="highlight" />
        </section>
      )}
      {!prospect && (
        <>
          <section className="firm-info flex">
            {/* {entity.location && (
              <CalloutCard
                calloutCardHeader="Location"
                calloutCardBody={entity.location}
                text
              />
            )} */}
            {contact && <ContactCard header="Lead Contact" contact={contact} />}
            <CalloutCard
              calloutCardHeader="Min Check"
              calloutCardBody={entity.min_check}
              money
            />
            <CalloutCard
              calloutCardHeader="Max Check"
              calloutCardBody={entity.max_check}
              money
            />
          </section>
          <section className="requirements flex">
            <BubbleCard header="Blacklist" terms={entity.blacklist} />
            <CalloutCard
              calloutCardHeader="Requires Revenue"
              calloutCardBody={entity.need_rev ? "Yes" : "No"}
              text
            />
            <CalloutCard
              calloutCardHeader="Early Intros"
              calloutCardBody={entity.prefers_early_intros ? "Yes" : "No"}
              text
            />
          </section>
          <section className="notes flex">
            <CalloutCard
              calloutCardHeader="Notes"
              calloutCardBody={entity.notes}
              text
            />
          </section>
        </>
      )}
      {prospect && (
        <section className="prospect-info-container flex">
          <UpdateFirmNotes entity={entity} setEntity={setEntity} />
          {!contact && (
            <section className="add-prospect-investor-container flex">
              <AddInvestorForm prospect firmId={entity.id} />
            </section>
          )}
          {contact && (
            <section className="update-prospect-investor-container flex">
              <UpdateInvestorForm
                prospect
                firmId={entity.id}
                contact={contact}
              />
            </section>
          )}
        </section>
      )}
      {admin && (
        <div className="buttons flex">
          <button className="edit-button" onClick={handleEdit}>
            Update
          </button>
          <button
            id="archive"
            className="archive-button"
            onClick={confirmArchive}
          >
            Archive
          </button>
          <button id="delete-button" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      )}
      {/* popups */}
      {/* confirm delete */}
      <div className="popup flex-col" id="confirm-delete">
        <button className="hide-popup" onClick={hideConfirmDeletePopup}>
          X
        </button>
        <h2 className="popup-header">You are about to delete {entity.name}</h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={handleDelete}>
            Confirm
          </button>
          <button className="popup-button" onClick={hideConfirmDeletePopup}>
            Cancel
          </button>
        </div>
      </div>
      {/* confirm archive */}
      <div className="popup flex-col" id="confirm-archive">
        <button className="hide-popup" onClick={hideConfirmArchivePopup}>
          X
        </button>
        <h2 className="popup-header">You are about to archive {entity.name}</h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={handleArchive}>
            Confirm
          </button>
          <button className="popup-button" onClick={hideConfirmArchivePopup}>
            Cancel
          </button>
        </div>
      </div>
      {/* success */}
      <div className="popup flex-col" id="success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          Deleted successfully
        </h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={hideSuccess}>
            To investors page
          </button>
        </div>
      </div>
      {/* fail */}
      <div className="popup flex-col" id="fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          There was an issue
        </h2>
      </div>
    </div>
  );
}
