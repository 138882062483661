import './Bubble.css'

export default function Bubble ({ bubbleText, type }) {
  let classNameBubbleType = 'bubble'
  if (type === 'highlight') {
    classNameBubbleType = 'bubble highlight'
  } else if (type === 'golden') {
    classNameBubbleType = 'bubble golden'
  }

  return (
    <div className={`${classNameBubbleType} flex`}>
      <p className="bubble-text">{bubbleText}</p>
    </div>
  )
}