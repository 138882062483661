import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FoundersRow = ({ founder }) => {
  const [company, setCompany] = useState(null);

  async function getData() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companies/${founder.company_id}`;

    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    const data = await res.json();
    if (res.status === 200) {
      setCompany(data.data);
    } else {
      setCompany(undefined);
    }
  }

  useEffect(() => {
    if (founder.company_id) {
      getData();
    } else {
      setCompany(undefined);
    }
  }, [founder.company_id]);

  function copyEmail() {
    navigator.clipboard.writeText(founder.email);
    alert("Email copied to clipboard");
  }

  return (
    <tr>
      <td>
        <Link to={`/tech/founders/update/${founder.id}`}>
          {founder.first_name + " " + founder.last_name}
          <span className="material-symbols-outlined">arrow_forward</span>
        </Link>
      </td>
      <td>
        {founder && founder.email ? founder.email : "N/A"}
        {founder && founder.email ? (
          <span
            className="material-symbols-outlined"
            id="copy-founder-email"
            onClick={copyEmail}
          >
            content_copy
          </span>
        ) : undefined}
      </td>
      <td>{founder.phone ? `${founder.phone}` : "N/A"}</td>
      <td>{company ? company.name : "N/A"}</td>
    </tr>
  );
};

export default FoundersRow;
