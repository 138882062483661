import "./UpdateInvestorForm.css";
import {
  TextInput,
  SelectInput,
  getValues,
  createOptions,
} from "../../FormComponents/FormInputs";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function UpdateInvestorForm({ prospect, firmId, contact }) {
  const [firmsDictionary, setFirmsDictionary] = useState();

  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const valueType = prospect ? "prospect firms" : "firms";
      const firms = await getValues(valueType);
      const firmsDictionaryObj = Object();

      firms.forEach((f) => {
        firmsDictionaryObj[f.name] = f.id;
      });
      if (isMounted) {
        setFirmsDictionary(firmsDictionaryObj);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => (isMounted = false);
  }, [prospect]);

  // create an option for each company and set initial values for company input element
  // note: initialValues are for element only, not formik
  useEffect(() => {
    const firmInput = document.getElementById("firm-input");
    if (firmsDictionary && firmInput) {
      createOptions(firmInput, firmsDictionary, [firmId]);
    }
  }, [firmsDictionary, firmId]);
  const hideSuccess = () =>
    (document.getElementById("add-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("add-fail").style.display = "none");

  return (
    <>
      <Formik
        initialValues={{
          firstName: contact.first_name,
          lastName: contact.last_name,
          title: contact.title,
          email: contact.email,
          linkedin: contact.linkedin,
          firmId: contact.firm_id,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          lastName: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          title: Yup.string().max(100, "Must be 100 characters or less"),
          email: Yup.string().email().required(),
          linkedin: Yup.string().nullable(),
          firmId: Yup.number(),
        })}
        onSubmit={async (values, { resetForm }) => {
          const endpoint = prospect ? "/investors/prospect" : "/investors";
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                first_name: values.firstName,
                last_name: values.lastName,
                title: values.title,
                email: values.email,
                linkedin: values.linkedin || undefined,
                firm_id: parseInt(values.firmId),
              }),
            }
          );
          const data = await res.json();
          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("add-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("add-fail").style.display = "flex";
          }
        }}
      >
        {({ values }) => (
          <Form id="add-investor" className="flex-col">
            <h2>Update investor</h2>
            <div className="inputs flex-col">
              <TextInput
                label="First name"
                name="firstName"
                type="text"
                placeholder="First name"
              />
              <TextInput
                label="Last name"
                name="lastName"
                type="text"
                placeholder="Last name"
              />
              <TextInput
                label="Title"
                name="title"
                type="text"
                placeholder="Title"
              />
              <TextInput
                label="Email"
                placeholder="name@email.com"
                name="email"
                type="email"
              />
              <TextInput
                label="LinkedIn"
                name="linkedin"
                type="text"
                placeholder="linkedin.com/example"
              />
              <SelectInput id="firm-input" label="Firm" name="firmId">
                <option value="">Select Firm</option>
              </SelectInput>
            </div>
            <button type="submit">Update investor</button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="add-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          Updated successfully
        </h2>
      </div>
      <div className="popup flex-col" id="add-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          There was an issue
        </h2>
      </div>
    </>
  );
}
