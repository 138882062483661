import "./UpdateFirmNotes.css";
import {
  TextArea,
} from "../../FormComponents/FormInputs";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function UpdateFirmNotes({
  entity,
  setEntity,
  contact,
  editing,
  setEditing,
}) {
  // set editing status to false
  // hides update form and shows company detail view on singleEntity page
  function handleCancel() {
    setEditing(false);
  }

  function hideSuccess() {
    document.getElementById("update-success").style.display = "none";
    setEditing(false);
  }

  function hideFail() {
    document.getElementById("update-fail").style.display = "none";
  }

  return (
    <div className="flex" id="update-firm-notes-form-container">
      <Formik
        initialValues={{
          notes: entity.notes,
        }}
        validationSchema={Yup.object({
          notes: Yup.string().nullable(),
        })}
        onSubmit={async (values, { resetForm }) => {
          // console.log(JSON.stringify(values));
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/firms/${entity.id}/prospect-notes`,
            {
              method: "PUT",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({ notes: values.notes }),
            }
          );
          const data = await res.json();

          if (res.status === 200) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("update-success").style.display = "flex";
            setEntity({...entity, notes: values.notes})
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("update-fail").style.display = "flex";
          }
        }}
      >
        {({ values, resetForm }) => (
          <Form id="update-firm-notes" className="update-form flex-col">
            <h2>Notes</h2>
            <div className="inputs flex-col">
              <TextArea
                label="Notes"
                name="notes"
                type="text"
                placeholder="Notes"
                rows="8"
                columns="40"
              />
            </div>
            <div className="form-buttons flex">
              <button type="submit">Save Changes</button>
            </div>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="update-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          Updated successfully
        </h2>
      </div>
      <div className="popup flex-col" id="update-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          There was an issue
        </h2>
      </div>
    </div>
  );
}
