import "./Footer.css";
import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <p className="footer-copyright">
        &copy; Bounce Innovation Hub, {new Date(Date.now()).getFullYear()}
      </p>
    </div>
  );
};

export default Footer;
