import "./CalloutCard.css";
import PrettyDollarAmount from "../PrettyDollar";

export default function CalloutCard({
  calloutCardHeader,
  calloutCardBody,
  money,
  text,
}) {
  return (
    <div className="callout-card">
      <h3 className="callout-card-text">{calloutCardHeader}</h3>
      <div className="callout-box flex">
        {money ? (
          <p className="callout-card-number">
            <PrettyDollarAmount amountInThousands={calloutCardBody} />
          </p>
        ) : undefined}
        {text ? (
          <p className="callout-card-text">{calloutCardBody}</p>
        ) : undefined}
        {!money && !text && calloutCardBody}
      </div>
    </div>
  );
}
