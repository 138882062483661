import "./BubbleCard.css";
import Bubble from "../Bubble/Bubble";

export default function BubbleCard({ header, terms }) {
  if (terms.length === 0 || terms[0] === "") {
    terms = undefined;
  }
  return (
    <div className="bubble-card">
      <h3 className="bubble-card-header">{header}</h3>
      <div className="bubbles-box flex">
        {terms ? (
          terms.map((t) => <Bubble bubbleText={t} />)
        ) : (
          <p className="bubbles-box-text">None</p>
        )}
      </div>
    </div>
  );
}
