import "./SearchBar.css";
import React from "react";
import { useState, useEffect } from "react";

const MatchSearchBar = (props) => {
  const { endpoint, functions, id } = props;
  const [placeholder, setPlaceholder] = useState("Enter your search term");
  const [valuesEndpoint, setValuesEndpoint] = useState(undefined);
  const [suggestionValues, setSuggestionValues] = useState(null);

  // set placeholder and valuesEndpoint on page load
  useEffect(() => {
    switch (endpoint) {
      case "/firms/company-match":
        setPlaceholder("Enter a company's name");
        setValuesEndpoint("/companies");
        break;
      default:
        break;
    }
  }, [endpoint]);

  // get suggestion values for search
  useEffect(() => {
    if (valuesEndpoint) {
      async function getSuggestions() {
        // console.log(valuesEndpoint);
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}${valuesEndpoint}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await res.json();
        const names = [];
        data.forEach((entity) => {
          if (entity.name) {
            names.push(entity.name);
          } else if (entity.first_name) {
            names.push(`${entity.first_name} ${entity.last_name}`);
          }
        });
        names.sort((a, b) => {
          const codeA = a.charCodeAt(0);
          const codeB = b.charCodeAt(0);
          if (codeA < codeB) {
            return -1;
          }
          if (codeA > codeB) {
            return 1;
          }
          return 0;
        });
        setSuggestionValues(names);
      }
      getSuggestions()
    }
  }, [valuesEndpoint]);

  // set suggestion values after getData called above
  useEffect(() => {
    if (suggestionValues) {
      suggestionValues.forEach((v, i) => {
        const newOption = document.createElement("option");
        newOption.value = v;

        document.getElementById("match-suggestions").append(newOption);
      });
    }
  }, [suggestionValues]);

  const handleClick = async (e) => {
    e.preventDefault();
    const input = document.getElementById(id);
    // console.log(endpoint);
    // console.log(input);
    // console.log(input.value);

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        searchValue: input.value,
      }),
      headers: {
        "CONTENT-TYPE": "application/json",
      },
    });
    if (res.status === 404) {
      functions[0](undefined);
    }
    const data = await res.json();
    functions[0](data.data);
    functions[1]("search results");
    functions[2](input.value);
  };

  const handleClear = async () => {
    const clearEndpoint = endpoint.split("/")[1];
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${clearEndpoint}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const data = await res.json();
    functions[0](data);
    functions[1]("all");
    document.getElementById(id).value = "";
  };

  return (
    <div className="search-bar-container flex">
      <input
        className="search-input"
        type="search"
        name=""
        list="match-suggestions"
        id={id}
        placeholder={placeholder}
      />
      <datalist id="match-suggestions"></datalist>
      <button
        className="searchbar-button"
        id="search"
        type="submit"
        onClick={handleClick}
      >
        Match
      </button>
      <button className="searchbar-button" id="clear" onClick={handleClear}>
        Clear
      </button>
    </div>
  );
};

export default MatchSearchBar;
