function sortTable(event) {
  if (event.target.tagName.toLowerCase() !== "span") {
    return;
  }
  let sortDirection = "forward";
  // reset color for th elements
  document.querySelectorAll("th").forEach((element) => {
    element.firstChild.style.color = "#0f3b53";
  });
  // set highlight for active th
  event.target.style.color = "#ef6b28";

  // remove existing sort indicators
  document.querySelectorAll("span.sort-indicator").forEach((element) => {
    element.parentElement.removeChild(element);
  });

  // create and append arrow indicator to clicked th
  const parentElement = event.target.parentElement;
  const pathIndicator = parentElement.appendChild(
    document.createElement("span")
  );
  pathIndicator.className = "material-symbols-outlined sort-indicator";

  if (parentElement.className === "sorted") {
    // toggle sort direction
    sortDirection = "reverse";
    document.querySelectorAll("th").forEach((element) => {
      element.className = "";
    });
    parentElement.className = "sorted-reverse";
    pathIndicator.textContent = "arrow_drop_up";
  } else {
    // toggle sort direction
    sortDirection = "forward";
    document.querySelectorAll("th").forEach((element) => {
      element.className = "";
    });
    parentElement.className = "sorted";
    pathIndicator.textContent = "arrow_drop_down";
  }

  // select table and indexing information
  const table = document.querySelector("table");
  const tbody = table.tBodies[0];
  const rows = Array.from(tbody.rows);
  const cellIndex = event.target.parentNode.cellIndex;
  const colName = event.target.innerText.toLowerCase();

  // sort
  if (colName === "name") {
    rows.sort((tr1, tr2) => {
      console.log(tr1.cells[cellIndex].firstElementChild);
      const tr1Text = tr1.cells[cellIndex].firstElementChild.textContent;
      const tr2Text = tr2.cells[cellIndex].firstElementChild.textContent;
      return sortDirection === "forward"
        ? tr1Text.localeCompare(tr2Text)
        : -tr1Text.localeCompare(tr2Text);
    });
  } else {
    rows.sort((tr1, tr2) => {
      const tr1Text = tr1.cells[cellIndex].textContent;
      const tr2Text = tr2.cells[cellIndex].textContent;
      // console.log(tr1Text, tr2Text);
      return sortDirection === "forward"
        ? tr1Text.localeCompare(tr2Text)
        : -tr1Text.localeCompare(tr2Text);
    });
  }

  tbody.append(...rows);
}

export default sortTable;
