import "./ContactsPage.css";
import React from "react";
import { Link } from "react-router-dom";

const ContactsPage = () => {
  return (
    <div className="links flex">
      <div className="links-grid">
        <Link className="contacts flex" to="/tech/contacts/investors/add">Add investor</Link>
        <Link className="contacts flex" to="/tech/contacts/founders/add">Add founder</Link>
      </div>
    </div>
  );
};

export default ContactsPage;
