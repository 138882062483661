import "./UpdateUserForm.css";
import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";

const UpdateUserForm = (props) => {
  const { user } = props;

  const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="field-holder flex">
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    );
  };

  const SelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="field-holder flex">
        <label htmlFor={props.id || props.name}>{label}</label>
        <select {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    );
  };

  const hideSuccess = () =>
    (document.getElementById("update-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("update-fail").style.display = "none");

  return (
    <>
      <Formik
        initialValues={{
          username: user[0].username,
          password: "",
          is_staff: user[0].is_staff,
          is_admin: user[0].is_admin,
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          password: Yup.string().max(100, "Must be 100 characters or less"),
          is_staff: Yup.bool().required(),
          is_admin: Yup.bool().required(),
        })}
        onSubmit={async (values, { resetForm }) => {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/users/update`,
            {
              method: "PUT",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                username: values.username,
                password: values.password,
                is_staff: values.is_staff,
                is_admin: values.is_admin,
              }),
            }
          );
          const data = await res.json();
          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("update-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("update-fail").style.display = "flex";
          }
        }}
      >
        {({ values }) => (
          <Form id="update-user" className="flex-col">
            <div className="inputs flex-col">
              <TextInput
                label="Username"
                name="username"
                type="text"
                placeholder="Username"
              />
              <TextInput
                label="Password"
                name="password"
                type="text"
                placeholder="Password"
              />
              <SelectInput label="Is Staff" name="is_staff">
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              <SelectInput label="Is Admin" name="is_admin">
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
            </div>
            <button type="submit">Update user</button>
            <button type="reset" onClick={(e) => Formik.resetForm}>
              Clear
            </button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="update-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">Updated succesfully</h2>
        <div className="popup-buttons flex">
          <Link className="popup-button" to="/tech/companies">
            See companies
          </Link>
          <button className="popup-button" onClick={hideSuccess}>
            Add another
          </button>
        </div>
      </div>
      <div className="popup flex-col" id="update-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">There was an issue</h2>
      </div>
    </>
  );
};

export default UpdateUserForm;
