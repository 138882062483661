import "./SearchBar.css";
import React from "react";
import { useState, useEffect } from "react";

const SearchBar = (props) => {
  const { endpoint, functions, id } = props;
  const [placeholder, setPlaceholder] = useState("Enter your search term");
  const [valuesEndpoint, setValuesEndpoint] = useState(undefined);
  const [suggestionValues, setSuggestionValues] = useState(null);

  async function getSuggestions() {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${valuesEndpoint}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const data = await res.json();
    const names = [];
    data.forEach((entity) => {
      if (entity.name) {
        names.push(entity.name);
      } else if (entity.first_name) {
        names.push(`${entity.first_name} ${entity.last_name}`);
      } else if (entity.username) {
        names.push(entity.username);
      }
    });
    setSuggestionValues(names);
  }

  // set placeholder and valuesEndpoint on page load
  useEffect(() => {
    switch (endpoint) {
      case "/firms/search":
        setPlaceholder("Enter a firm's name");
        setValuesEndpoint("/firms");
        break;
      case "/companies/search":
        setPlaceholder("Enter a company name");
        setValuesEndpoint("/companies");
        break;
      case "/investors/search":
        setPlaceholder("Enter an investor's name");
        setValuesEndpoint("/investors");
        break;
      case "/founders/search":
        setPlaceholder("Enter a founder's name");
        setValuesEndpoint("/founders");
        break;
      case "/users/search":
        setPlaceholder("Enter a username");
        setValuesEndpoint("/users");
        break;
      default:
        break;
    }
  }, []);

  // get suggestion values for search
  useEffect(() => {
    if (valuesEndpoint) {
      getSuggestions();
    }
  }, [valuesEndpoint]);

  // set suggestion values after getData called above
  useEffect(() => {
    if (suggestionValues) {
      suggestionValues.forEach((v, i) => {
        const newOption = document.createElement("option");
        newOption.value = v;

        document.getElementById("suggestions").append(newOption);
      });
    }
  }, [suggestionValues]);

  const handleClick = async (e) => {
    e.preventDefault();
    const input = document.getElementById("search");
    // console.log(endpoint);
    // console.log(input);
    // console.log(input.value);

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          searchValue: input.value,
        }),
        headers: {
          "CONTENT-TYPE": "application/json",
        },
      }
    );
    if (res.status === 404) {
      functions[0](undefined);
    }
    const data = await res.json();
    switch (endpoint) {
      case "/firms/company-match":
        functions[0](data.data);
        functions[1]("search results");
        functions[2](input.value);
        break;
      case "/users/search":
        functions[0](data.data);
        if (document.getElementById("update-user")) {
          document.getElementById("update-user").style.display = "flex";
        }
        break;
      default:
        // set entity data to returned value
        functions[0](data.data);
        // set view to search results
        functions[1]("search results");
        break;
    }
  };

  const handleClear = async () => {
    const clearEndpoint = endpoint.split("/")[1];
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${clearEndpoint}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const data = await res.json();
    functions[0](data);
    document.getElementById("search").value = "";
    switch (clearEndpoint) {
      case "users":
        if (document.getElementById("update-user")) {
          document.getElementById("update-user").style.display = "none";
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="search-bar-container flex">
      <input
        type="search"
        name=""
        list="suggestions"
        id="search"
        placeholder={placeholder}
      />
      <datalist id="suggestions"></datalist>
      <button
        className="searchbar-button"
        id="search"
        type="submit"
        onClick={handleClick}
      >
        Search
      </button>
      <button className="searchbar-button" id="clear" onClick={handleClear}>
        Clear
      </button>
    </div>
  );
};

export default SearchBar;
