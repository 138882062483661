import "./StyledList.css";

export default function StyledList({ listHeader, listItems }) {
  return (
    <div className="styled-list-wrapper flex-col">
      <h3 className="styled-list-header">{listHeader}</h3>
      <div className="list-wrapper flex">
        <ul className="styled-list">
          {listItems.length > 0 ? (
            listItems.map((item, index) => {
              return (
                <li className="styled-list-item" key={index}>
                  {item}
                </li>
              );
            })
          ) : (
            <li className="styled-list-item">None</li>
          )}
        </ul>
      </div>
    </div>
  );
}
