import "./CompanyDetails.css";
import { useHistory } from "react-router";
import Bubble from "../../EntityInfoComponents/Bubble/Bubble.js";
import CalloutCard from "../../EntityInfoComponents/CalloutCard/CalloutCard";
import StyledList from "../../EntityInfoComponents/StyledList/StyledList";
import ContactCard from "../../EntityInfoComponents/ContactCard/ContactCard";

export default function CompanyDetails({ entity, contact, setEditing, admin }) {
  const history = useHistory();

  function handleEdit() {
    setEditing(true);
  }

  function confirmDelete() {
    document.getElementById("confirm-delete").style.display = "flex";
  }

  async function handleDelete() {
    hideConfirmDeletePopup();

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/companies/${entity.id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
    const data = await res.json();

    if (res.status === 200) {
      document.getElementById("success-header").innerText = data.message;
      document.getElementById("delete-success").style.display = "flex";
    } else {
      document.getElementById("fail-header").innerText = data.message;
      document.getElementById("delete-fail").style.display = "flex";
    }
  }

  const hideConfirmDeletePopup = () =>
    (document.getElementById("confirm-delete").style.display = "none");

  const hideSuccess = () => {
    document.getElementById("delete-success").style.display = "none";
    history.push("/tech/companies");
  };

  const hideFail = () =>
    (document.getElementById("delete-fail").style.display = "none");

  return (
    <div className="company-container flex-col">
      <h1 className="company-name">{entity.name}</h1>
      <section className="info-bubbles flex">
        <Bubble bubbleText={entity.program} type="highlight" />
        {entity.raising && <Bubble bubbleText="Raising" type="golden" />}
      </section>
      <section className="sector-bubbles flex">
        {entity.sectors.map((s, i) => {
          return <Bubble bubbleText={s} type="normal" key={i} />;
        })}
      </section>
      <section className="company-info flex">
        <ContactCard header="Founder" contact={contact} />
        <CalloutCard
          calloutCardHeader="Total Revenue"
          calloutCardBody={entity.total_revenue}
          money={true}
        />
        <CalloutCard
          calloutCardHeader="MRR"
          calloutCardBody={entity.monthly_revenue}
          money={true}
        />
      </section>
      {entity.raising && (
        <div className="raise-info">
          <h2 id="raise">Raise</h2>
          <section className="raise-callouts flex">
            <CalloutCard
              calloutCardHeader="Amount"
              calloutCardBody={entity.amount}
              money={true}
            />
            <CalloutCard
              calloutCardHeader="Pre"
              calloutCardBody={entity.pre}
              money={true}
            />
            <CalloutCard
              calloutCardHeader="Raised"
              calloutCardBody={entity.total_raised}
              money={true}
            />
            <CalloutCard
              calloutCardHeader="Left in Round"
              calloutCardBody={entity.left_in_round}
              money={true}
            />
          </section>
          {entity.introd_to.length > 0 && (
            <section className="outreach-info flex">
              <StyledList
                listHeader={"Introd To"}
                listItems={entity.introd_to}
              />
              <StyledList
                listHeader={"Not Responded"}
                listItems={entity.not_responded}
              />
              <StyledList
                listHeader={"Passed By"}
                listItems={entity.passed_by}
              />
              <StyledList
                listHeader={"Call With"}
                listItems={entity.call_with}
              />
              <StyledList
                listHeader={"Checks From"}
                listItems={entity.checks_from}
              />
            </section>
          )}
        </div>
      )}
      {admin && (
        <div className="buttons flex">
          <button className="edit-button" onClick={handleEdit}>
            Update
          </button>
          <button id="delete-company-button" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      )}
      {/* delete popups */}
      {/* confirm */}
      <div className="popup flex-col" id="confirm-delete">
        <button className="hide-popup" onClick={hideConfirmDeletePopup}>
          X
        </button>
        <h2 className="popup-header">You are about to delete {entity.name}</h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={handleDelete}>
            Confirm
          </button>
          <button className="popup-button" onClick={hideConfirmDeletePopup}>
            Cancel
          </button>
        </div>
      </div>
      {/* success */}
      <div className="popup flex-col" id="delete-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          Deleted successfully
        </h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={hideSuccess}>
            To companies page
          </button>
        </div>
      </div>
      {/* fail */}
      <div className="popup flex-col" id="delete-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          There was an issue
        </h2>
      </div>
    </div>
  );
}
