import React, { useEffect, useState } from "react";

const SuiteRow = ({ suite, index }) => {
  const [company, setCompany] = useState(null);

  async function getData() {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/companies/${suite.company_id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const json = await res.json();
    setCompany(json.data);
  }

  useEffect(() => {
    if (suite.company_id) {
      getData();
    }
  }, []);

  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td>{suite.id}</td>
      <td>{company ? company.name : "Unoccupied"}</td>
      <td>{suite.square_footage}</td>
      <td>{suite.has_electric ? <p>&#x2714;</p> : <p>&#x2717;</p>}</td>
      <td>{suite.is_water_ready ? <p>&#x2714;</p> : <p>&#x2717;</p>}</td>
      <td>{suite.has_air_conditioning ? <p>&#x2714;</p> : <p>&#x2717;</p>}</td>
      <td>{suite.is_lab ? <p>&#x2714;</p> : <p>&#x2717;</p>}</td>
      <td>{suite.has_hood ? <p>&#x2714;</p> : <p>&#x2717;</p>}</td>
    </tr>
  );
};

export default SuiteRow;
