import "./FirmsPage.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FilterForm from "../../Components/Filter/FilterForm";
import MatchSearchBar from "../../Components/SearchBar/MatchSearchBar";
import FirmTable from "../../Components/Firms/FirmTable/FirmTable";

const FirmsPage = () => {
  const [firms, setFirms] = useState(null);
  const [view, setView] = useState("all");
  const [company, setCompany] = useState(null);
  const location  = useLocation()

    // get firm data from api on page load
    useEffect(() => {
      async function getData() {
        // load filtered data
        if (
          location.pathname.includes("/tech/investors/filter/sectors")
        ) {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/firms/filter/sectors`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                filters: [
                  location.pathname
                    .split("/")
                    [location.pathname.split("/").length - 1].replace("-", " "),
                ],
              }),
            }
          );
          const data = await res.json();
          const filteredData = data.data;
          setFirms(filteredData);
        } else if (
          location.pathname.includes("/tech/investors/filter/stages")
        ) {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/firms/filter/stages`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                filters: [
                  location.pathname
                    .split("/")
                    [location.pathname.split("/").length - 1].replace("-", " "),
                ],
              }),
            }
          );
          const data = await res.json();
          const filteredData = data.data;
          setFirms(filteredData);
        } else {
          // load all firm data if no filers applied
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/firms`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          const data = await res.json();
          setFirms(data);
        }
      }
      getData();
    }, []);

  function handleCopy() {
    const firmNames = [];
    firms.forEach((f) => firmNames.push(f.name));
    navigator.clipboard.writeText(firmNames.join(","));
    alert(`Firm list copied to clipboard`);
  }

  function handleShowFilters() {
    document.getElementById("filters").style.display = "flex";
    document.getElementById("show-filters").style.display = "none";
    document.getElementById("hide-filters").style.display = "block";
  }

  function handleHideFilters() {
    document.getElementById("filters").style.display = "none";
    document.getElementById("hide-filters").style.display = "none";
    document.getElementById("show-filters").style.display = "block";
  }

  if (firms === null) {
    return <h2 className="loading-header">Loading firms...</h2>;
  } else if (firms === undefined) {
    return (
      <div className="page-container flex-col">
        <h1 id="firms-header">Investors</h1>
        <div className="searches-container flex">
          <div className="search-container flex-col">
            <h2>Match investors to company</h2>
            <MatchSearchBar
              endpoint="/firms/company-match"
              functions={[setFirms, setView, setCompany]}
              id="firm-match-search"
            />
          </div>
        </div>
        <h2>No match found</h2>
      </div>
    );
  } else {
    return (
      <div className="page-container flex-col">
        <div className="flex" id="firm-page-header-container">
          <h1 id="firms-header">Investors</h1>
          <div className="match-bar flex">
            <MatchSearchBar
              endpoint="/firms/company-match"
              functions={[setFirms, setView, setCompany]}
              id="firm-match-search"
            />
            <div id="buttons">
              <button id="show-filters" onClick={handleShowFilters}>
                Filters
              </button>
              <button id="hide-filters" onClick={handleHideFilters}>
                Hide filters
              </button>
              <Link id="add-firm-link" to="/tech/investors/add">
                Add
              </Link>
            </div>
          </div>
        </div>
        <div className="filters flex" id="filters">
          <div className="filter flex-col">
            <h3 className="filter-header">Filter by stage</h3>
            <FilterForm
              filterEndpoint="/firms/filter/stages"
              clearEndpoint="/firms"
              valueType="stages"
              functions={[setFirms]}
            />
          </div>
          <div className="filter flex-col">
            <h3 className="filter-header">Filter by sector</h3>
            <FilterForm
              filterEndpoint="/firms/filter/sectors"
              clearEndpoint="/firms"
              valueType="sectors"
              functions={[setFirms]}
            />
          </div>
        </div>
        <FirmTable firms={firms} />
      </div>
    );
  }
};

export default FirmsPage;
