import "./CompanyTable.css";
import React from "react";
import CompanyRow from "../CompanyRow/CompanyRow";
import sortTable from "../../TableFunctions/sortTable";

const CompanyTable = ({ companies }) => {
  return (
    <div className="company-table-container flex-col">
      <table id="company-table">
        <thead>
          <tr>
            <th onClick={sortTable} className="sorted">
              <span>Name ({companies.length})</span>
              <span className="material-symbols-outlined sort-indicator">
                arrow_drop_down
              </span>
            </th>
            <th onClick={sortTable}>
              <span>Program</span>
            </th>
            <th onClick={sortTable}>
              <span>Founder</span>
            </th>
            <th onClick={sortTable}>
              <span>Email</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {companies.length > 0 &&
            companies.map((company, index) => {
              return <CompanyRow company={company} key={index} index={index} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyTable;
