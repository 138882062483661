import "./ResetPasswordForm.css";
import React from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";

const ResetPasswordForm = () => {
  const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="field-holder flex">
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    );
  };

  const hideSuccess = () =>
    (document.getElementById("reset-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("reset-fail").style.display = "none");

  return (
    <div className="update-form-container flex" id='update-password-form-container'>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("You must set a new password"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
          ),
        })}
        onSubmit={async (values, { resetForm }) => {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/reset-password`,
            {
              method: "PUT",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                password: values.password,
              }),
            }
          );
          const data = await res.json();
          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("reset-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("reset-fail").style.display = "flex";
          }
        }}
      >
        {({ values }) => (
          <Form id="reset-password" className="flex-col">
            <h2>Reset Password</h2>
            <div className="inputs flex-col">
              <TextInput
                label="Password"
                name="password"
                type="text"
                placeholder="Password"
              />
              <TextInput
                label="Confirm Password"
                name="confirmPassword"
                type="text"
                placeholder="Confirm Password"
              />
            </div>
            <button type="submit">Reset Password</button>
            <button type="reset" onClick={(e) => Formik.resetForm}>
              Clear
            </button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="reset-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">Password reset</h2>
        <div className="popup-buttons flex">
        </div>
      </div>
      <div className="popup flex-col" id="reset-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">We encountered an error</h2>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
