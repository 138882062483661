import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  NumberInput,
  SelectInput,
} from "../../Components/FormComponents/FormInputs";

const MatchSuitesForm = ({ setSuites, setView }) => {
  function hideFail() {
    document.getElementById("update-fail").style.display = "none";
  }

  return (
    <Formik
      initialValues={{
        size: 0,
        has_electric: "",
        is_water_ready: "",
        has_air_conditioning: "",
        is_lab: "",
        has_hood: "",
      }}
      validationSchema={Yup.object({
        size: Yup.number().min(0).nullable(),
        has_electric: Yup.bool().nullable(),
        is_water_ready: Yup.bool().nullable(),
        has_air_conditioning: Yup.bool().nullable(),
        is_lab: Yup.bool().nullable(),
        has_hood: Yup.bool().nullable(),
      })}
      onSubmit={async (values, { resetForm }) => {
        const criteriaObj = {};
        for (const [key, value] of Object.entries(values)) {
          if (value) {
            criteriaObj[key] =
              value === "true" ? true : value === "false" ? false : value;
          }
        }
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/suites/match`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "CONTENT-TYPE": "application/json",
            },
            body: JSON.stringify({
              criteria: criteriaObj,
            }),
          }
        );
        const data = await res.json();

        document.getElementById("suite-match-popup-container").style.display =
          "none";
        document.getElementById("suite-match-popup").style.display = "none";

        if (res.status === 200) {
          resetForm();
          setSuites(data.data);
          setView("search");
        } else {
          document.getElementById("suite-match-popup-container").style.display =
            "flex";
          document.getElementById("suite-match-popup").style.display = "flex";
        }
      }}
    >
      {({ values, resetForm }) => (
        <Form id="match-suite" className="match-suite flex-col">
          <button
            id="hide-match-suites"
            type="button"
            className="flex"
            onClick={() => {
              resetForm();
              document.getElementById(
                "suite-match-popup-container"
              ).style.display = "none";
              document.getElementById("suite-match-popup").style.display =
                "none";
              document.getElementById("match-suite").style.display = "none";
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </button>
          <h2 id="suites-form-header">Find Suites</h2>
          <div className="inputs flex-col">
            <NumberInput
              label="Minimum Square Footage"
              name="size"
              type="number"
              placeholder="0"
            />
            <SelectInput label="Electricity" name="has_electric">
              <option value="">Select electricity needs</option>
              <option value={undefined}>Not Required</option>
              <option value={true}>Required</option>
            </SelectInput>
            <SelectInput label="Water" name="is_water_ready">
              <option value="">Select water needs</option>
              <option type="bool" value={undefined}>
                Not Required
              </option>
              <option value={true}>Required</option>
            </SelectInput>
            <SelectInput label="Air Conditioning" name="has_air_conditioning">
              <option value="">Select AC needs</option>
              <option value={undefined}>Not Required</option>
              <option value={true}>Required</option>
            </SelectInput>
            <SelectInput label="Lab" name="is_lab">
              <option value="">Select laboratory needs</option>
              <option value={undefined}>Can be</option>
              <option value={false}>Must not be</option>
              <option value={true}>Must be</option>
            </SelectInput>
            <SelectInput label="Hood" name="has_hood">
              <option value="">Select hood needs</option>
              <option value={undefined}>Can have</option>
              <option value={false}>Must not have</option>
              <option value={true}>Must have</option>
            </SelectInput>
          </div>
          <div className="form-buttons flex">
            <button
              className="form-action-button"
              id="submit-suite-match-form"
              type="submit"
            >
              Search
            </button>
            <button
              className="form-action-button"
              type="button"
              onClick={() => {
                resetForm();
              }}
            >
              Clear
            </button>
          </div>
          <div className="popup flex-col" id="match-fail">
            <button className="hide-popup" onClick={hideFail}>
              X
            </button>
            <h2 className="popup-header" id="fail-header">
              There was an issue
            </h2>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MatchSuitesForm;
