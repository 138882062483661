import "./FirmProspectPage.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FilterForm from "../../Components/Filter/FilterForm";
import FirmList from "../../Components/Firms/FirmList/FirmList";
import FirmTable from "../../Components/Firms/FirmTable/FirmTable";

export default function FirmProspectPage() {
  const [firms, setFirms] = useState(null);
  const location = useLocation();

  // get firm data from api on page load
  useEffect(() => {
    async function getData() {
      if (
        location.pathname.includes("/tech/investors/prospect/filter/investorStatus")
      ) {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/firms/filter/investor-status`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "CONTENT-TYPE": "application/json",
            },
            body: JSON.stringify({
              filters: [
                location.pathname
                  .split("/")
                  [location.pathname.split("/").length - 1].replace("-", " "),
              ],
            }),
          }
        );
        const data = await res.json();
        const filteredData = data.data;
        setFirms(filteredData);
      } else if (
        location.pathname.includes("/tech/investors/prospect/filter/relationships")
      ) {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/firms/filter/relationship`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "CONTENT-TYPE": "application/json",
            },
            body: JSON.stringify({
              filters: [
                location.pathname
                  .split("/")
                  [location.pathname.split("/").length - 1].replace("-", " "),
              ],
            }),
          }
        );
        const data = await res.json();
        const filteredData = data.data;
        setFirms(filteredData);
      } else {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/firms/outreach`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await res.json();
        setFirms(data);
      }
    }
    getData();
  }, []);

  function handleShowFilters() {
    document.getElementById("filters").style.display = "flex";
    document.getElementById("show-filters").style.display = "none";
    document.getElementById("hide-filters").style.display = "block";
  }

  function handleHideFilters() {
    document.getElementById("filters").style.display = "none";
    document.getElementById("hide-filters").style.display = "none";
    document.getElementById("show-filters").style.display = "block";
  }

  if (firms === null) {
    return <h2 className="loading-header">Loading firms...</h2>;
  } else if (firms === undefined) {
    return (
      <div className="page-container flex-col">
        <h1 id="firms-header">Prospects</h1>
        <h2>No match found</h2>
      </div>
    );
  } else {
    return (
      <div
        id="prospect-firm-page-container"
        className="page-container flex-col"
      >
        <div className="flex" id="firm-prospect-page-header-container">
          <h1 id="firms-header">Prospects</h1>
          <div className="show-hide-buttons flex" id="buttons">
            <button id="show-filters" onClick={handleShowFilters}>
              Filters
            </button>
            <button id="hide-filters" onClick={handleHideFilters}>
              Hide filters
            </button>
          </div>
        </div>
        <div className="filters flex" id="filters">
          <div className="filter flex-col">
            <h3 className="filter-header">Filter by relationship</h3>
            <FilterForm
              filterEndpoint="/firms/filter/relationship"
              clearEndpoint="/firms/outreach"
              valueType="relationships"
              functions={[setFirms]}
            />
          </div>
          <div className="filter flex-col">
            <h3 className="filter-header">Filter by Investor Status</h3>
            <FilterForm
              filterEndpoint="/firms/filter/investor-status"
              clearEndpoint="/firms/outreach"
              valueType="investorStatus"
              functions={[setFirms]}
            />
          </div>
        </div>
        <FirmTable firms={firms} prospect />
      </div>
    );
  }
}
