import "./SingleEntity.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import FirmDetails from "../../Components/DetailedViews/Firm/FirmDetails";
import CompanyDetails from "../../Components/DetailedViews/Company/CompanyDetails";
import UpdateCompanyForm from "../../Components/UpdateForms/Company/UpdateCompanyForm";
import UpdateFirmForm from "../../Components/UpdateForms/Firm/UpdateFirmForm";
import UpdateFounderForm from "../../Components/UpdateForms/Founders/UpdateFounderForm";
import NotFound from "../404/NotFound";

const SingleEntity = ({ entityType, admin, prospect }) => {
  const { id } = useParams();
  const [endpoint, setEndpoint] = useState(undefined);
  const [entity, setEntity] = useState(undefined);
  const [contact, setContact] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const [unauthorized, setUnauthorized] = useState(undefined);

  // update api endpoint when entityType changes
  useEffect(() => {
    if (entityType === "firm") {
      const endpoint = prospect ? `/firms/prospect/${id}` : `/firms/${id}`;
      setEndpoint(endpoint);
    } else if (entityType === "company") {
      setEndpoint(`/companies/${id}`);
    } else if (entityType === "founder") {
      setEndpoint(`/founders/${id}`)
    }
  }, [entityType, id, prospect]);

  // fetch new data when api endpoint changes
  useEffect(() => {
    async function getData() {
      if (endpoint) {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await res.json();
        if (res.status === 200) {
          // console.log(data.data);
          if (entityType === "founder") {
            setEntity(data);
          } else {
            setEntity(data.data);
          }
        } else if (res.status === 403) {
          setUnauthorized(true);
        } else {
          setEntity({ error: `No ${entityType} found` });
        }
      }
    }
    getData();
  }, [entityType, endpoint, editing]);

  // fetch new contact data when entity data changes
  useEffect(() => {
    async function getContact() {
      if (entity && (entity.investor_id || entity.founder_id)) {
        let contactEndpoint =
          entityType === "firm"
            ? prospect
              ? `/investors/prospect/${entity.investor_id}`
              : `/investors/${entity.investor_id}`
            : `/founders/${entity.founder_id}`;
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}${contactEndpoint}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await res.json();
        if (res.status === 200) {
          setContact(data);
        } else {
          console.log(res.status, data.message);
        }
      }
    }
    getContact();
  }, [entityType, entity, prospect]);

  let Jsx = () => {
    if (entity) {
      if (!editing) {
        if (entityType === "firm") {
          return (
            <FirmDetails
              entity={entity}
              setEntity={setEntity}
              contact={contact}
              setEditing={setEditing}
              admin={admin}
              prospect={prospect}
            />
          );
        } else if (entityType === "company") {
          return (
            <CompanyDetails
              entity={entity}
              contact={contact}
              setEditing={setEditing}
              admin={admin}
            />
          );
        } else if (entityType === "founder") {
          return (
            <UpdateFounderForm
              entity={entity}
              contact={contact}
              setEditing={setEditing}
            />
          );
        }
      } else {
        if (entityType === "firm") {
          return (
            <UpdateFirmForm
              entity={entity}
              contact={contact}
              setEditing={setEditing}
            />
          );
        } else if (entityType === "company") {
          return (
            <UpdateCompanyForm
              entity={entity}
              contact={contact}
              setEditing={setEditing}
            />
          );
        }
      }
    } else {
      if (unauthorized) {
        return <NotFound />
      } else {
        return <h1>Loading...</h1>;
      }
    }
  };

  return <Jsx />;
};

export default SingleEntity;
