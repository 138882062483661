import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CompanyRow = ({ company, index }) => {
  const [founder, setFounder] = useState(null);

  async function getData() {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/founders/${company.founder_id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const data = await res.json();
    setFounder(data);
  }

  useEffect(() => {
    if (company.founder_id) {
      getData();
    }
  }, []);

  function copyEmail() {
    navigator.clipboard.writeText(founder.email);
    alert("Email copied to clipboard");
  }

  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td>
        <Link to={`/tech/companies/${company.id}`}>
          {company.name}{" "}
          <span className="material-symbols-outlined">arrow_forward</span>
        </Link>
      </td>
      <td>{company.program}</td>

      <td>{founder ? `${founder.first_name} ${founder.last_name}` : "N/A"}</td>
      <td>
        {founder && founder.email ? founder.email : "N/A"}
        {founder && founder.email ? (
          <span
            className="material-symbols-outlined"
            id="copy-founder-email"
            onClick={copyEmail}
          >
            content_copy
          </span>
        ) : undefined}
      </td>
    </tr>
  );
};

export default CompanyRow;
