import "./FoundersTable.css";
import React from "react";
import FoundersRow from "../FoundersRow/FoundersRow";
import sortTable from "../../TableFunctions/sortTable";

const FoundersTable = ({ founders }) => {
  return (
    <div className="founders-page-container flex-col">
      <table id="founders-table">
        <thead>
          <tr>
            <th onClick={sortTable} className="sorted">
              <span>Name ({founders.length})</span>
              <span className="material-symbols-outlined sort-indicator">
                arrow_drop_down
              </span>
            </th>
            <th onClick={sortTable}>
              <span>Email</span>
            </th>
            <th onClick={sortTable}>
              <span>Phone Number</span>
            </th>
            <th onClick={sortTable}>
              <span>Company</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {founders.length > 0 &&
            founders.map((founder, index) => {
              return <FoundersRow founder={founder} key={index} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default FoundersTable;
