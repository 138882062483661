import "./TechHomePage.css";
import React from "react";
import { Link } from "react-router-dom";

const TechHomePage = ({ staff }) => {
  return (
    <div className="tech-home-page flex">
      <div className="tech-links-grid">
        {staff && (
          <>
            <Link className="tech-home-link flex" to="/tech/investors">
              Investors
            </Link>
            <Link className="tech-home-link flex" id="companies" to="/tech/companies">
              Companies
            </Link>
            <Link className="tech-home-link flex" id="contacts" to="/tech/contacts">
              Contacts
            </Link>
          </>
        )}
        <Link className="tech-home-link flex" id="prospects" to="/tech/investors/prospect">
          Investor Prospects
        </Link>
      </div>
    </div>
  );
};

export default TechHomePage;
