import "./UpdateUserPage.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../Components/SearchBar/SearchBar";
import UpdateUserForm from "../../Components/UpdateForms/User/UpdateUserForm";

const UpdateUserPage = (props) => {
  const { admin } = props;
  const [user, setUser] = useState(null);

  if (admin) {
    return (
      <div className="update-user-page-container flex-col">
        <h1>Update user</h1>
        <SearchBar
          endpoint="/users/search"
          functions={[setUser]}
          id="user-search"
        />
        {user && <UpdateUserForm user={user}/> }
      </div>
    );
  } else if (!admin) {
    return (
      <div className="admin-page-container flex">
        <h1>You don't have permission to access this page</h1>
        <Link to="/">Home</Link>
      </div>
    );
  }
};

export default UpdateUserPage;
