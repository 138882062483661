const PrettyDollarAmount = ({ amountInThousands }) => {
  amountInThousands = amountInThousands ? parseInt(amountInThousands) : undefined;
  if (amountInThousands === undefined || amountInThousands === null) {
    return "N/A";
  } else if (amountInThousands === 0) {
    return `$${amountInThousands}`;
  } else if (amountInThousands < 1000 && amountInThousands > 0) {
    return `$${amountInThousands}K`;
  } else {
    return `$${amountInThousands / 1000}M`;
  }
};

export default PrettyDollarAmount;
