import { useField } from "formik";

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="field-holder flex">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="text-area-holder flex">
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="text-area-wrapper">
        <textarea className="textarea" {...field} {...props}></textarea>
      </div>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const NumberInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="field-holder flex">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="number-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="select-field-holder flex">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select className="select-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const MultiSelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="multi-select-field-holder flex">
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="multi-select-element-wrapper">
        <select className="multi-select-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

// flexible function to source values for getOptions
export async function getValues(entityType) {
  let endpoint = undefined;

  switch (entityType) {
    case "founders":
      endpoint = "/founders";
      break;
    case "investors":
      endpoint = "/investors";
      break;
    case "sectors":
      endpoint = "/firms/info/sectors";
      break;
    case "companies":
      endpoint = "/companies";
      break;
    case "firms":
      endpoint = "/firms";
      break;
    case "prospect firms":
      endpoint = "/firms/outreach";
      break;
    case "stages":
      endpoint = "/firms/info/stages";
      break;
    case "programs":
      endpoint = "/companies/info/programs";
      break;
    case "relationships":
      return [
        "not contacted",
        "responded",
        "unresponsive",
        "delay",
        "scheduled",
        "contacted",
        "generic",
      ];
    case "investorStatus":
      return ["has investor", "no investor"]
    default:
      endpoint = "/";
  }

  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    const values = data.data || data;
    return values;
  } catch (e) {
    console.log(e);
  }
}

// creates option elements for selectInput and multiSelectInput elements
export function createOptions(input, values, initialValues, valueType) {
  try {
    // list of existing options to prevent duplication
    const existingOptions = input.children;
    const existingValues = [];
    for (let i = 0; i < existingOptions.length; i++) {
      existingValues.push(existingOptions.item(i).value);
    }
    // list of created options, used to select initialValues if provided
    const options = [];

    // create options from values prop
    // handles values of type Array or Object
    if (Array.isArray(values)) {
      if (valueType === "stages") {
        const mappedStages = values.map((s, i) => {
          let order = undefined;
          switch (s) {
            case "pre-seed":
              order = 0;
              break;
            case "seed":
              order = 1;
              break;
            case "a":
              order = 2;
              break;
            case "b":
              order = 3;
              break;
            case "c":
              order = 4;
              break;
            default:
              order = 100;
          }
          return { value: s, order: order };
        });
        mappedStages.sort((a, b) => a.order - b.order);
        values = mappedStages.map((object) => object.value);
      } else {
        values.sort();
      }
      values.forEach((v, i) => {
        if (!existingValues.includes(v)) {
          const newOption = document.createElement("option");
          newOption.value = v;
          newOption.innerText = v;
          input.append(newOption);
          options.push(newOption);
        }
      });
    } else if (values) {
      const keyValuePairs = Object.entries(values);
      keyValuePairs.sort();

      for (let i = 0; i < keyValuePairs.length; i++) {
        if (!existingValues.includes(keyValuePairs[i][1])) {
          const newOption = document.createElement("option");
          newOption.value = parseInt(keyValuePairs[i][1]);
          newOption.innerText = keyValuePairs[i][0];
          input.append(newOption);
          options.push(newOption);
        }
      }
    }

    // select initial values if provided
    // console.log(initialValues)
    if (initialValues.length > 0) {
      options.forEach((o) => {
        if (
          initialValues.includes(o.value) ||
          initialValues.includes(parseInt(o.value))
        ) {
          o.selected = true;
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export const DateInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="field-holder flex">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="boolean-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
