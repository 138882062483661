import "./NavBar.css";
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = ({
  loggedIn,
  setLoggedIn,
  admin,
  setAdmin,
  staff,
  setStaff,
  setDefaultView,
}) => {
  const location = useLocation();
  const routes = location.pathname.split("/");

  useEffect(() => {
    async function getAuthStatus() {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}`, {
        method: "GET",
        credentials: "include",
      });
      if (res.status === 403) {
        setLoggedIn(false);
        setStaff(false);
        setAdmin(false);
        setDefaultView(null);
      }
      const data = await res.json();
      setLoggedIn(data.authenticated);
      setStaff(data.staff);
      setAdmin(data.admin);
      setDefaultView(data.default_view);
    }
    getAuthStatus();
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/") {
      return;
    }
    const activeLink = document.querySelector("a.active");

    const existingIndicator = document.querySelector("span.path-indicator");

    if (existingIndicator) {
      existingIndicator.parentElement.removeChild(existingIndicator);
    }
    if (activeLink) {
      const pathIndicator = activeLink.appendChild(
        document.createElement("span")
      );
      pathIndicator.className = "material-symbols-outlined path-indicator";
      pathIndicator.textContent = "chevron_left";
    }
  }, [location]);

  return (
    <div id="header-container" className="header-container-vertical flex-col">
      <div className="header-logo-container">
        <NavLink exact={true} to={"/"}>
          <img
            src="/BOUNCE_InnovationHub_horiz-white.png"
            alt="The Bounce Innovation Hub logo"
            className="header-logo"
          />
        </NavLink>
      </div>
      <div className="header-nav-container">
        <ul className="header-nav-list flex-col" id="header-nav-list">
          {!loggedIn && (
            <>
              <li id="header-nav-login">
                <NavLink to={"/login"} className="header-nav-link">
                  Login
                </NavLink>
              </li>
            </>
          )}
          {loggedIn && (
            <>
              {routes.includes("tech") ? (
                <li className="location-indicator">Tech/</li>
              ) : routes.includes("grow") ? (
                <li className="location-indicator">GROW/</li>
              ) : undefined}

              {routes.includes("tech") && staff && (
                <>
                  <li>
                    <NavLink
                      to={"/tech/investors"}
                      isActive={(match, location) => {
                        if (!match) {
                          return false;
                        }

                        if (location.pathname.includes("prospect")) {
                          return false;
                        }

                        return true;
                      }}
                      className="header-nav-link"
                    >
                      Investors
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tech/investors/prospect"
                      className="header-nav-link"
                    >
                      Investor Prospects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/tech/companies"} className="header-nav-link">
                      Companies
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tech/contacts" className="header-nav-link">
                      Contacts
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tech/founders" className="header-nav-link">
                      Founders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tech/suites" className="header-nav-link">
                      Suites
                    </NavLink>
                  </li>
                </>
              )}
              {routes.includes("grow") && staff && (
                <>
                  <li>
                    <NavLink to={"/grow/ae"} className="header-nav-link">
                      AE
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/grow/mortar"} className="header-nav-link">
                      MORTAR
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/grow/next-level"}
                      className="header-nav-link"
                    >
                      Next Level
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/grow/suites" className="header-nav-link">
                      Suites
                    </NavLink>
                  </li>
                </>
              )}
              {admin && (
                <li>
                  <NavLink to={"/admin"} className="header-nav-link">
                    Admin
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to={"/account"} className="header-nav-link">
                  Account
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <ul className="bottom-nav-bar">
          {staff && (
            <li id="tech-grow-nav-button">
              <NavLink
                id="tech-grow-nav-link"
                to={location.pathname.includes("/tech") ? "/grow" : "/tech"}
                className="bottom-nav-link"
              >
                <div className="tech-grow">
                  {location.pathname.includes("/tech") ? "To GROW" : "To Tech"}
                  <span
                    className="material-symbols-outlined"
                    id="switch-button-arrow"
                  >
                    arrow_forward
                  </span>
                </div>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to={"/logout"} className="bottom-nav-link">
              Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
