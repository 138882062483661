import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import SuitesPage from "./pages/SuitesPage/SuitesPage";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import CompaniesPage from "./pages/CompaniesPage/CompaniesPage";
import FirmsPage from "./pages/FirmsPage/FirmsPage";
import SingleEntity from "./pages/SingleEntity/SingleEntity";
import HomePage from "./pages/HomePage/HomePage";
import TechHomePage from "./pages/TechHomePage/TechHomepage";
import GrowHomePage from "./pages/GrowHomePage/GrowHomePage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import AddInvestorForm from "./Components/AddForms/Investor/AddInvestorForm";
import AddFounderForm from "./Components/AddForms/Founder/AddFounderForm";
import AddFirmForm from "./Components/AddForms/Firm/AddFirmForm";
import AddCompanyForm from "./Components/AddForms/Company/AddCompanyForm";
import AdminPage from "./pages/AdminPage/AdminPage";
import UpdateUserPage from "./pages/UpdateUserPage/UpdateUserPage";
import DeleteUserPage from "./pages/DeleteUserPage/DeleteUserPage";
import AccountPage from "./pages/AccountPage/AccountPage";
import FirmProspectPage from "./pages/FirmProspectPage/FirmProspectPage";
import NotFound from "./pages/404/NotFound";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import SetDefaultViewPage from "./pages/SetDefaultViewPage/SetDefaultViewPage";
import FoundersPage from "./pages/FoundersPage/FoundersPage"
import UpdateFounderForm from "./Components/UpdateForms/Founders/UpdateFounderForm"
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  const [loggedIn, setLoggedIn] = useState(null);
  const [staff, setStaff] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [defaultView, setDefaultView] = useState(null);

  useEffect(() => {
    async function getAuthStatus() {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}`, {
        method: "GET",
        credentials: "include",
      });
      if (res.status === 403) {
        setLoggedIn(false);
        setStaff(false);
        setAdmin(false);
        setDefaultView(null);
      }
      const data = await res.json();
      setLoggedIn(data.authenticated);
      setStaff(data.staff);
      setAdmin(data.admin);
      setDefaultView(data.default_view);
    }
    getAuthStatus();
  }, []);

  return (
    <>
      {!loggedIn && (
        <Router>
          <LoginPage
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
            setAdmin={setAdmin}
            setStaff={setStaff}
            setDefaultView={setDefaultView}
          />
        </Router>
      )}
      {loggedIn && !staff && (
        <Router>
          <div className="app-container">
            <NavBar
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              admin={admin}
              setAdmin={setAdmin}
              staff={staff}
              setStaff={setStaff}
              setDefaultView={setDefaultView}
            />
            <div className="content-container">
              <Switch>
                <Route path="/account/resetpassword">
                  <ResetPasswordPage />
                </Route>
                <Route path="/account/resetview">
                  <SetDefaultViewPage />
                </Route>
                <Route path="/account">
                  <AccountPage />
                </Route>
                <Route path="/logout">
                  <LogoutPage setLoggedIn={setLoggedIn} />
                </Route>
                <Route exact path="/tech">
                  <TechHomePage staff={staff} />
                </Route>
                <Route path="/tech/investors/prospect">
                  <FirmProspectPage />
                </Route>
                <Route exact path="/tech/investors/:id">
                  <SingleEntity
                    entityType="firm"
                    admin={admin}
                    staff={staff}
                    prospect={true}
                  />
                </Route>
                <Route exact path="/">
                  {defaultView === "tech" ? (
                    <Redirect to="/tech" />
                  ) : defaultView === "grow" ? (
                    <Redirect to="/grow" />
                  ) : defaultView === "prospect" ? (
                    <Redirect to="/tech/investors/prospect" />
                  ) : (
                    <HomePage />
                  )}
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Footer />
            </div>
          </div>
        </Router>
      )}
      {loggedIn && staff && (
        <Router>
          <div className="app-container">
            <NavBar
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              admin={admin}
              setAdmin={setAdmin}
              staff={staff}
              setStaff={setStaff}
              setDefaultView={setDefaultView}
            />
            <div className="content-container">
              <Switch>
                {admin && (
                  <Route path="/register">
                    <RegistrationPage admin={admin} />
                  </Route>
                )}
                {admin && (
                  <Route path="/users/update">
                    <UpdateUserPage admin={admin} />
                  </Route>
                )}
                {admin && (
                  <Route path="/users/delete">
                    <DeleteUserPage admin={admin} />
                  </Route>
                )}
                {admin && (
                  <Route path="/admin">
                    <AdminPage admin={admin} />
                  </Route>
                )}
                <Route path="/tech/suites">
                  <SuitesPage />
                </Route>
                <Route path="/grow/suites">
                  <SuitesPage />
                </Route>
                <Route path="/account/resetpassword">
                  <ResetPasswordPage />
                </Route>
                <Route path="/account/resetview">
                  <SetDefaultViewPage />
                </Route>
                <Route path="/account">
                  <AccountPage />
                </Route>
                <Route path="/logout">
                  <LogoutPage setLoggedIn={setLoggedIn} />
                </Route>
                <Route exact path="/tech">
                  <TechHomePage staff={staff} />
                </Route>
                <Route exact path="/tech/investors/add">
                  <AddFirmForm />
                </Route>
                <Route path="/tech/investors/prospect">
                  <FirmProspectPage />
                </Route>
                <Route exact path="/tech/investors/:id">
                  <SingleEntity entityType="firm" admin={admin} staff={staff} />
                </Route>
                <Route path="/tech/investors">
                  <FirmsPage />
                </Route>
                <Route exact path="/tech/companies/add">
                  <AddCompanyForm />
                </Route>
                <Route exact path="/tech/companies/:id">
                  <SingleEntity
                    entityType="company"
                    admin={admin}
                    staff={staff}
                  />
                </Route>
                <Route path="/tech/companies">
                  <CompaniesPage />
                </Route>
                <Route exact path="/tech/contacts">
                  <ContactsPage />
                </Route>
                <Route exact path="/tech/contacts/investors/add">
                  <AddInvestorForm />
                </Route>
                <Route exact path="/tech/contacts/founders/add">
                  <AddFounderForm />
                </Route>
                <Route exact path="/tech/founders/update/:id">
                  <UpdateFounderForm />
                </Route>
                <Route path="/tech/founders">
                  <FoundersPage />
                </Route>
                <Route exact path="/grow">
                  <GrowHomePage />
                </Route>
                <Route exact path="/">
                  {defaultView === "tech" ? (
                    <Redirect to="/tech" />
                  ) : defaultView === "grow" ? (
                    <Redirect to="/grow" />
                  ) : defaultView === "prospect" ? (
                    <Redirect to="/tech/investors/prospect" />
                  ) : (
                    <HomePage />
                  )}
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Footer />
            </div>
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
