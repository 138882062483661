import "./FirmTable.css";
import React from "react";
import FirmRow from "../FirmRow/FirmRow";
import sortTable from "../../TableFunctions/sortTable";

const FirmTable = ({ firms, prospect }) => {
  return (
    <div className="firm-table-container flex-col">
      <table id="firm-table">
        <thead>
          <tr>
            <th onClick={sortTable} className="sorted">
              <span>Name ({firms.length})</span>
              <span className="material-symbols-outlined sort-indicator">arrow_drop_down</span>
            </th>
            <th onClick={sortTable}>
              <span>Relationship</span>
            </th>
            <th onClick={sortTable}>
              <span>Contact</span>
            </th>
            <th onClick={sortTable}>
              <span>Contact Email</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {firms.length > 0 &&
            firms.map((firm, index) => {
              return (
                <FirmRow
                  firm={firm}
                  key={index}
                  index={index}
                  prospect={prospect}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default FirmTable;
