import "./AddFirmForm.css";
import {
  TextInput,
  TextArea,
  SelectInput,
  MultiSelectInput,
  NumberInput,
  DateInput,
  getValues,
  createOptions,
} from "../../FormComponents/FormInputs";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const AddFirmForm = () => {
  const history = useHistory();
  const [sectors, setSectors] = useState([]);
  const [investorDictionary, setInvestorDictionary] = useState();

  // get all sectors covered by firms in db on page load
  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const sectors = await getValues("sectors");
      const investors = await getValues("investors");
      const investorDictionaryObj = Object();

      investors.forEach((i) => {
        const name = `${i.first_name} ${i.last_name}`;
        investorDictionaryObj[name] = i.id;
      });

      if (isMounted) {
        setSectors(sectors);
        setInvestorDictionary(investorDictionaryObj);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // create an option for each sector/investor and set initial values for sector-select/investor-select input elements
  // note: initialValues are for element only, not formik. Set to an empty array to select none by default
  useEffect(() => {
    const sectorInputs = [
      document.getElementById("sector-select"),
      document.getElementById("blacklist-select"),
    ];
    sectorInputs.forEach((input) => {
      if (sectors && input) {
        createOptions(input, sectors, []);
      }
    });
    const investorInput = document.getElementById("investor-input");
    if (investorDictionary && investorInput) {
      createOptions(investorInput, investorDictionary, []);
    }
  }, [sectors, investorDictionary]);

  const hideSuccess = () =>
    (document.getElementById("add-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("add-fail").style.display = "none");

  return (
    <div className="flex" id="add-firm-form-container">
      <Formik
        initialValues={{
          name: "",
          website: "",
          location: "",
          notes: "",
          relationship: "",
          metDate: new Date(Date.now()).toISOString().split("T")[0],
          minCheck: 0,
          maxCheck: 0,
          prefersLead: false,
          stages: [],
          sectors: [],
          blacklist: [],
          needRev: false,
          prefersEarlyIntros: false,
          specialRequirements: "",
          ownershipTargetLow: null,
          ownershipTargetHigh: null,
          investorId: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          website: Yup.string().url("Invalid URL format"),
          location: Yup.string(),
          notes: Yup.string(),
          relationship: Yup.string()
            .oneOf(["Met", "Warm"], 'Must be "Met" or "Warm"')
            .required("Required"),
          metDate: Yup.date().required("Required"),
          minCheck: Yup.number()
            .min(0, "Must be positive")
            .max(1000, "Enter value in thousands (10,000 = 10)"),
          maxCheck: Yup.number()
            .min(10, "Must be > 10")
            .max(100000, "Enter value in thousands (10,000 = 10)"),
          prefersLead: Yup.bool(),
          stages: Yup.array()
            .min(1, "You must choose at least 1 stage")
            .of(Yup.string())
            .required(),
          sectors: Yup.array()
            .min(1, "You must choose at least 1 sector")
            .of(Yup.string())
            .required(),
          blacklist: Yup.array().of(Yup.string()).required(),
          needRev: Yup.bool(),
          prefersEarlyIntros: Yup.bool(),
          specialRequirements: Yup.string().nullable(),
          ownershipTargetLow: Yup.number()
            .max(20, "Enter value as an integer from 1-20 (5% = 5)")
            .min(1, "Enter value as an integer from 1-20 (5% = 5)")
            .nullable(),
          ownershipTargetHigh: Yup.number()
            .max(20, "Enter value as an integer from 2-20 (5% = 5)")
            .min(2, "Enter value as an integer from 2-20 (5% = 5)")
            .nullable(),
          investorId: Yup.number(),
        })}
        onSubmit={async (values, { resetForm }) => {
          // console.log("submitting");
          // console.log(JSON.stringify(values));
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/firms`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                name: values.name,
                website: values.website,
                location: values.location,
                notes: values.notes,
                relationship: values.relationship,
                met_date: values.metDate,
                min_check: values.minCheck,
                max_check: values.maxCheck,
                prefers_lead: values.prefersLead,
                stages: values.stages.join(","),
                sectors: values.sectors.join(","),
                blacklist: values.blacklist.join(","),
                need_rev: values.needRev,
                prefers_early_intros: values.prefersEarlyIntros,
                special_requirements: values.specialRequirements,
                ownership_target_low: values.ownershipTargetLow,
                ownership_target_high: values.ownershipTargetHigh,
                investor_id: parseInt(values.investorId),
              }),
            }
          );
          const data = await res.json();

          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("add-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("add-fail").style.display = "flex";
          }
        }}
      >
        {({ values }) => (
          <Form id="add-firm" className="add-form flex-col">
            <h2>Add firm</h2>
            <div className="inputs flex-col">
              <TextInput
                label="Firm Name"
                name="name"
                type="text"
                placeholder="Firm name"
              />
              <TextInput
                label="Website"
                name="website"
                type="text"
                placeholder="Website"
              />
              <TextInput
                label="Location"
                name="location"
                type="text"
                placeholder="Location (e.g. Los Angeles, CA)"
              />
              <TextArea
                label="Notes"
                name="notes"
                type="text"
                placeholder="Notes"
                rows="8"
                columns="40"
              />
              <SelectInput label="Relationship" name="relationship">
                <option value="">Select a relationship</option>
                <option value="Met">Met</option>
                <option value="Warm">Warm</option>
              </SelectInput>
              <DateInput label="Date met" name="metDate" type="date" />
              <NumberInput
                label="Minimum check size ($ thousands)"
                name="minCheck"
                type="number"
                placeholder="$100,000 = 100"
              />
              <NumberInput
                label="Maximum check size ($ thousands)"
                name="maxCheck"
                type="number"
                placeholder="$100,000 = 100"
              />
              <SelectInput label="Prefers to lead" name="prefersLead">
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              {/* stages checkbox */}
              <MultiSelectInput
                name="stages"
                label="Stages"
                multiple="multiple"
                id="stage-select"
              >
                <option name="stages" value="pre-seed">
                  Pre-seed
                </option>
                <option name="stages" value="seed">
                  Seed
                </option>
                <option name="stages" value="A">
                  A
                </option>
                <option name="stages" value="B">
                  B
                </option>
                <option name="stages" value="C">
                  C
                </option>
              </MultiSelectInput>
              <MultiSelectInput
                name="sectors"
                label="Sectors"
                multiple="multiple"
                id="sector-select"
              ></MultiSelectInput>
              {/* blacklist checkbox, need to refactor to FieldArray */}
              <MultiSelectInput
                name="blacklist"
                label="Blacklist"
                multiple="multiple"
                id="blacklist-select"
              ></MultiSelectInput>
              <SelectInput label="Requires revenue" name="needRev">
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              <SelectInput
                label="Prefers early intros"
                name="prefersEarlyIntros"
              >
                <option value="">Select an option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectInput>
              <TextInput
                label="Special requirements"
                name="specialRequirements"
                type="text"
                placeholder="Special requirements"
              />
              <NumberInput
                label="Target ownership % (low)"
                name="ownershipTargetLow"
                type="number"
                placeholder="3% = 3"
              />
              <NumberInput
                label="Target ownership % (high)"
                name="ownershipTargetHigh"
                type="number"
                placeholder="3% = 3"
              />
              <SelectInput
                id="investor-input"
                label="Investor"
                name="investorId"
              >
                <option value="">Select an investor</option>
              </SelectInput>
            </div>
            <button type="submit">Add firm</button>
            <div className="clear-cancel-buttons flex">
              <button type="reset" onClick={(e) => Formik.resetForm()}>
                Clear
              </button>
              <button
                type="button"
                className="back-button"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="add-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          Added successfully
        </h2>
        <div className="popup-buttons flex">
          <Link className="popup-button" to="/tech/investors">
            See investors
          </Link>
          <button className="popup-button" onClick={hideSuccess}>
            Add another
          </button>
        </div>
      </div>
      <div className="popup flex-col" id="add-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          There was an issue
        </h2>
      </div>
    </div>
  );
};

export default AddFirmForm;
