import './SetDefaultViewPage.css'
import React from 'react'
import { useState, useEffet } from "react"
import { SelectInput } from '../../Components/FormComponents/FormInputs'
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";

const SetDefaultViewPage = () => {

  return (
    <div className="update-form-container flex" id='update-view-form-container'>
      <Formik
        initialValues={{
          defaultView: "",
        }}
        /*
        validationSchema={Yup.object({
          defaultView: Yup.string()
            .required()
        })}*/
        onSubmit={async (values, { resetForm }) => {
          console.log('submitting');
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/reset-view`,
            {
              method: "PUT",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                default_view: values.defaultView,
              }),
            }
          );
          const data = await res.json();
          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("reset-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("reset-fail").style.display = "flex";
          }
        }}
      >
        {({ values }) => (
          <Form id="reset-view-form" className="flex-col">
            <h2>Reset Default View</h2>
            <div className="inputs flex-col" id="reset-view">
              <SelectInput
                label="Reset Default View"
                name="defaultView"
                type="select"
                id="reset-view"
              >
                <option value="tech">Tech</option>
                <option value="grow">GROW</option>
                <option value="prospects">Prospects</option>
                <option value="suites">Suites</option>
              </SelectInput>
            </div>
            <button type="submit">Reset Default View</button>
            <button type="reset" onClick={(e) => Formik.resetForm}>
              Clear
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SetDefaultViewPage