import "./FilterForm.css";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  getValues,
  createOptions,
  MultiSelectInput,
} from "../FormComponents/FormInputs";
import { Formik, Form } from "formik";

const FilterForm = (props) => {
  const { filterEndpoint, clearEndpoint, valueType, functions } = props;
  let history = useHistory();
  const location = useLocation();
  // const [filterMessage, setFilterMessage] = useState("Filters");

  const [filterValues, setFilterValues] = useState([]);

  // get all values of specified type
  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const values = await getValues(valueType);

      if (isMounted) {
        setFilterValues(values);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => {
      isMounted = false;
    };
  }, [valueType]);

  // create an option for each value
  useEffect(() => {
    const filterInput = document.getElementById(`${valueType}-select-input`);
    if (filterValues && filterInput) {
      createOptions(filterInput, filterValues, [], valueType);
    }
  }, [valueType, filterValues]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ filters: [] }}
        onSubmit={async (values) => {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}${filterEndpoint}`,
            {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                filters: values.filters,
              }),
              headers: {
                "CONTENT-TYPE": "application/json",
              },
            }
          );
          const data = await res.json();
          const filteredData = data.data;

          functions[0](filteredData);
          history.push(location.pathname);
          history.push(
            `${location.pathname.split('/filter')[0]}/filter/${valueType}/${values.filters[0].replace(" ", "-")}`
          );
        }}
        onReset={async (values, { resetForm }) => {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}${clearEndpoint}`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          const data = await res.json();
          functions[0](data);
          history.push(location.pathname.split('/filter')[0]);
          const selected = document.querySelectorAll("input:checked");
          selected.forEach((input, i) => {
            input.checked = 0;
          });
        }}
      >
        {() => (
          <Form
            className="filter-form flex-col"
            id={`${valueType}-filter-form`}
          >
            <MultiSelectInput
              id={`${valueType}-select-input`}
              name="filters"
              multiple="multiple"
            ></MultiSelectInput>
            <div className="filter-buttons flex">
              <button className="filter-button" id="filter" type="submit">
                Filter
              </button>
              <button className="filter-button" id="clear-filter" type="reset">
                Clear
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FilterForm;
