import "./RegistrationPage.css";
import React, { useState, useEffect } from "react";

const RegistrationPage = (props) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [defaultView, setDefaultView] = useState(null);

  useEffect(() => {
    // console.log(`username: ${username} | password: ${password}`);
  }, [username, password, defaultView]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleViewChange = (e) => {
    setDefaultView(e.target.value);
  }

  const handleClick = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/users/register`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
          defaultView: defaultView,
        }),
      }
    );

    const data = await res.json();
    if (res.status === 201) {
      document.getElementById("success-header").innerText = data.message;
      document.getElementById("add-success").style.display = "flex";
    } else {
      document.getElementById("fail-header").innerText = data.message;
      document.getElementById("add-fail").style.display = "flex";
    }
  };

  const hideSuccess = () =>
    (document.getElementById("add-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("add-fail").style.display = "none");

  return (
    <div className="register-page-container flex-col">
      <h1 className="register-header">Add User</h1>
      <div className="register-form-container flex-col">
        <input
          className="register-input"
          id="username-input"
          type="text"
          placeholder="username"
          onChange={handleUsernameChange}
        />
        <input
          className="register-input"
          id="password-input"
          type="text"
          placeholder="password"
          onChange={handlePasswordChange}
        />
        <div className="default-view-container flex-row">
          <label for="view">Choose default view:</label>
          <select className="default-view" id="view" onChange={handleViewChange}>
            <option value="tech">Tech</option>
            <option value="grow">GROW</option>
            <option value="prospect">Prospect</option>
            <option value="suites">Suites</option>
          </select>
        </div>
        <button className="register-button" onClick={handleClick}>
          Create user
        </button>
      </div>
      <div className="popup flex-col" id="add-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">
          User created
        </h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={hideSuccess}>
            Add another
          </button>
        </div>
      </div>
      <div className="popup flex-col" id="add-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">
          Something went wrong, please try again
        </h2>
      </div>
    </div>
  );
};

export default RegistrationPage;
