import "./SuitesPage.css";
import React, { useEffect, useState } from "react";
import SuiteTable from "../../Components/Suites/SuiteTable/SuiteTable";
import MatchSuitesForm from "./MatchSuitesForm";

const SuitesPage = () => {
  const [suites, setSuites] = useState(null);
  const [view, setView] = useState("all");

  async function getData() {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/suites`, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    setSuites(data);
  }

  // get suites data from api on page load
  useEffect(() => {
    getData();
  }, []);

  function handleShowPopup() {
    document.getElementById("match-suite").style.display = "flex";
    document.getElementById("suite-match-popup-container").style.display =
      "flex";
    document.getElementById("suite-match-popup").style.display = "flex";
  }

  if (suites === null) {
    return <h2 className="loading-header">Loading suites...</h2>;
  } else if (suites === undefined) {
    return <h2>suites is undefined</h2>;
  } else {
    return (
      <div className="page-container flex-col">
        <div id="suite-match-popup-container" className="flex">
          <div id="suite-match-popup" className="flex">
            <MatchSuitesForm setSuites={setSuites} setView={setView} />
          </div>
        </div>
        <div className="flex" id="suites-page-header-container">
          <h2 id="suites-page-header">Suites</h2>
          <button id="show-suite-popup" onClick={handleShowPopup}>
            <span className="material-symbols-outlined">search</span>
          </button>
          {view === "search" && (
            <button
              id="show-suite-clear"
              onClick={() => {
                setView("all");
                getData();
              }}
            >
              <span className="material-symbols-outlined">close</span>
            </button>
          )}
        </div>

        <SuiteTable suites={suites} />
      </div>
    );
  }
};

export default SuitesPage;
