import "./SuiteTable.css";
import React from "react";
import SuiteRow from "../SuiteRow/SuiteRow";
import sortTable from "../../TableFunctions/sortTable";

const SuiteTable = ({ suites }) => {
  return (
    <div className="suite-table flex-col">
      <table id="suites-table">
        <thead>
          <tr>
            <th onClick={sortTable} className="sorted">
              <span>Number ({suites.length})</span>
              <span className="material-symbols-outlined sort-indicator">
                arrow_drop_down
              </span>
            </th>
            <th onClick={sortTable}>
              <span>Tenant</span>
            </th>
            <th onClick={sortTable}>
              <span>Size</span>
            </th>
            <th onClick={sortTable}>
              <span>Electric</span>
            </th>
            <th onClick={sortTable}>
              <span>Water</span>
            </th>
            <th onClick={sortTable}>
              <span>A/C</span>
            </th>
            <th onClick={sortTable}>
              <span>Lab</span>
            </th>
            <th onClick={sortTable}>
              <span>Hood</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {suites.length > 0 &&
            suites.map((suite, index) => {
              return <SuiteRow suite={suite} key={index} index={index} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SuiteTable;
