import "./LoginPage.css";
import React, { useState, useEffect } from "react";

const LoginPage = (props) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [badField, setBadField] = useState(null);
  const { loggedIn, setLoggedIn, setAdmin, setStaff, setDefaultView } = props;
  const userAgent = navigator.userAgent;

  // updater functions for username and password
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // attempt a login on button press
  const handleClick = async () => {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    // const jsonRes = await res.json();
    // console.log(jsonRes.message);
    // console.log(res.status);
    const s = res.status;
    if (s === 200) {
      const data = await res.json();
      setLoggedIn(data.authenticated);
      setAdmin(data.admin);
      setStaff(data.staff);
      setDefaultView(data.default_view);
      document.removeEventListener("keydown", loginOnEnter);
    } else if (s === 404) {
      setBadField("user");
    } else if (s === 401) {
      setBadField("pass");
    }
  };

  const handleRetry = async () => {
    setBadField(null);
    handleClick();
  };

  function showNoUsePopup() {
    document.getElementById("no-use-popup").style.display = "flex";
  }

  function showNonChromiumHeader() {
    document.getElementById("non-chromium-no-use-header").style.display =
      "block";
  }

  function handleHideNoUsePopup() {
    document.getElementById("no-use-popup").style.display = "none";
  }

  // no-use popup functions

  useEffect(() => {
    if (window.innerWidth > 1000 && !userAgent.match(/chrome|chromium/i)) {
      showNoUsePopup();
      showNonChromiumHeader();
    }
  }, [userAgent]);

  // add event listener to complete login attempt on enter key press in password input field
  function loginOnEnter(event) {
    if (event.key === "Enter") {
      // event.preventDefault();
      document.getElementById("login-button").click();
      document.removeEventListener("keydown", loginOnEnter);
    }
  }

  if (!loggedIn) {
    document.addEventListener("keydown", loginOnEnter, { once: true });
  }

  if (!loggedIn) {
    return (
      <div className="login-page-container">
        <div className="login-form-container flex-col">
          <h2 id="login-form-header">Team Member Login</h2>
          <input
            className="login-input"
            id="username-input"
            type="text"
            placeholder="username"
            onChange={handleUsernameChange}
          />
          <input
            className="login-input"
            id="password-input"
            type="password"
            placeholder="password"
            onChange={handlePasswordChange}
          />
          {!badField && (
            <button
              id="login-button"
              className="login-button"
              onClick={handleClick}
            >
              Log in
            </button>
          )}
          {badField === "user" && (
            <p id="wrong-password">
              We didn't find an account with that username
            </p>
          )}
          {badField === "pass" && (
            <p id="wrong-password">That password wasn't correct</p>
          )}
          {badField && (
            <>
              <button className="login-retry-button" onClick={handleRetry}>
                Login
              </button>
            </>
          )}
        </div>
        <div className="login-img-container flex">
          <img
            id="login-img"
            src="/BOUNCE_InnovationHub_stacked_RGB.png"
            alt="Bounce Innovation Hub logo"
          />
        </div>
        <div id="no-use-popup" className="flex">
          <button id="close-no-use-popup" onClick={handleHideNoUsePopup}>
            <span className="material-symbols-outlined">close</span>
          </button>
          <h1 className="no-use-header" id="non-chromium-no-use-header">
            This app is designed for chromium-based browsers. Your experience
            may be degraded.
          </h1>
          <h1 className="no-use-header" id="small-screen-no-use-header">
            This app is designed for desktop use. Your experience may be
            degraded.
          </h1>
        </div>
      </div>
    );
  }
};

export default LoginPage;
