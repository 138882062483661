import "./AddFounderForm.css";
import {
  TextInput,
  SelectInput,
  getValues,
  createOptions,
} from "../../FormComponents/FormInputs";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const AddFounderForm = () => {
  const [companyDictionary, setCompanyDictionary] = useState();

  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const companies = await getValues('companies');
      const companyDictionaryObj = Object();

      companies.forEach((c) => {
        companyDictionaryObj[c.name] = c.id;
      });
      if (isMounted) {
        setCompanyDictionary(companyDictionaryObj);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => (isMounted = false);
  }, []);

   // create an option for each company and set initial values for company input element
  // note: initialValues are for element only, not formik
  useEffect(() => {
    const companyInput = document.getElementById("company-input");
    if (companyDictionary && companyInput) {
      createOptions(companyInput, companyDictionary, [])
    }
  }, [companyDictionary]);

  const hideSuccess = () =>
    (document.getElementById("add-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("add-fail").style.display = "none");

  return (
    <div className="add-form-container" id="add-founder-form-container">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          companyId: "",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          lastName: Yup.string()
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          email: Yup.string().email().required(),
          phone: Yup.string(),
          companyId: Yup.number(),
        })}
        onSubmit={async (values, { resetForm }) => {
          // console.log("submitting");
          // console.log(JSON.stringify(values));
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/founders`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                phone: values.phone,
                company_id: parseInt(values.companyId),
              }),
            }
          );
          const data = await res.json();
          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("add-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("fail-header").innerText = data.message;
            document.getElementById("add-fail").style.display = "flex";
          }
        }}
      >
        {({ values }) => (
          <Form id="add-founder" className="flex-col">
            <h2>Add founder</h2>
            <div className="inputs flex-col">
              <TextInput
                label="First name"
                name="firstName"
                type="text"
                placeholder="First name"
              />
              <TextInput
                label="Last name"
                name="lastName"
                type="text"
                placeholder="Last name"
              />
              <TextInput
                label="Email"
                name="email"
                placeholder="name@email.com"
                type="email"
              />
              <TextInput
                label="Phone number"
                name="phone"
                type="text"
                placeholder="123-456-7890"
              />
              <SelectInput
                id='company-input'
                label="Company"
                name="companyId"
              >
                <option value="">Select Company</option>
              </SelectInput>
            </div>
            <button type="submit">Add founder</button>
            <button type="reset" onClick={(e) => Formik.resetForm()}>
              Clear
            </button>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="add-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">Added successfully</h2>
        <div className="popup-buttons flex">
          <button className="popup-button" onClick={hideSuccess}>
            Add another
          </button>
        </div>
      </div>
      <div className="popup flex-col" id="add-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">There was an issue</h2>
      </div>
    </div>
  );
};

export default AddFounderForm;
