import './AccountPage.css'
import React from 'react'
import { Link } from 'react-router-dom';


const AccountPage = () => {
  return (
    <div className="acc-home-page flex">
      <div className="acc-links-grid">
        <Link className="acc-home-link flex" id="pass" to="/account/resetpassword">
          Reset Password
        </Link>
        <Link className="acc-home-link flex" id="view" to="/account/resetview">
          Set Default View
        </Link>
      </div>
    </div>
  )
}



export default AccountPage