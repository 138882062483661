import "./AddCompanyForm.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  TextInput,
  NumberInput,
  SelectInput,
  MultiSelectInput,
  DateInput,
  getValues,
  createOptions,
} from "../../FormComponents/FormInputs";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const AddCompanyForm = ({ functions }) => {
  const history = useHistory();
  const [sectors, setSectors] = useState([]);
  const [founderDictionary, setFounderDictionary] = useState();

  // get all sectors covered by firms in db on page load
  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const sectors = await getValues('sectors');
      const founders = await getValues('founders');
      const founderDictionaryObj = Object();

      founders.forEach((f) => {
        const name = `${f.first_name} ${f.last_name}`
        founderDictionaryObj[name] = f.id;
      });

      if (isMounted) {
        setSectors(sectors);
        setFounderDictionary(founderDictionaryObj);
      }
    }
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // create an option for each sector/founder and set initial values for sector-select/founder-select input elements
  // note: initialValues are for element only, not formik
  useEffect(() => {
    const sectorInput = document.getElementById("sector-select");
    if (sectors && sectorInput) {
      createOptions(sectorInput, sectors, []);
    }
    const founderInput = document.getElementById("founder-input");
    if (founderDictionary && founderInput) {
      createOptions(founderInput, founderDictionary, [])
    }
  }, [sectors, founderDictionary]);

  const hideSuccess = () =>
    (document.getElementById("add-success").style.display = "none");

  const hideFail = () =>
    (document.getElementById("add-fail").style.display = "none");

  return (
    <div className="flex" id="add-company-form-container">
      <Formik
        initialValues={{
          founderId: "",
          sectors: [],
          program: null,
          foundedDate: new Date(Date.now()).toISOString().split("T")[0],
          raising: false,
          amount: 0,
          pre: 0,
          leftInRound: 0,
          name: "",
          totalRevenue: 0,
          monthlyRevenue: 0,
          introdTo: "",
          interestFrom: "",
          passedBy: "",
          passedByFounder: "",
          callWith: "",
          checksFrom: "",
          totalRaised: 0,
        }}
        validationSchema={Yup.object().shape({
          founderId: Yup.number('Must be a number').min(0, 'Must be a positive integer').required('You must select a founder'),
          sectors: Yup.array().min(1, 'You must choose at least 1 sector').of(Yup.string()).required(),
          program: Yup.string().required(),
          foundedDate: Yup.date('Must be a date').required(),
          raising: Yup.bool('Must be a bool').required(),
          amount: Yup.number('Must be a number').min(0, "Must be a positive integer"),
          pre: Yup.number('Must be a number'),
          leftInRound: Yup.number('Must be a number').min(0, "Must be positive"),
          name: Yup.string('Must be a string')
            .max(100, "Must be 100 characters or less")
            .required("Required"),
          totalRevenue: Yup.number('Must be a number').min(0, "Must be positive"),
          monthlyRevenue: Yup.number('Must be a number').min(0, "Must be positive"),
          introdTo: Yup.string('Must be a string'),
          interestFrom: Yup.string('Must be a string'),
          passedBy: Yup.string("Must be a string"),
          passedByFounder: Yup.string("Must be a string"),
          callWith: Yup.string('Must be a string'),
          checksFrom: Yup.string('Must be a string'),
          totalRaised: Yup.number('Must be a number').min(0, "Must be positive"),
        })}
        onSubmit={async (values, { resetForm }) => {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/companies`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "CONTENT-TYPE": "application/json",
              },
              body: JSON.stringify({
                founder_id: parseInt(values.founderId),
                sectors: values.sectors.join(","),
                program: values.program,
                founded_date: values.foundedDate,
                raising: values.raising,
                amount: values.amount,
                pre: values.pre,
                left_in_round: values.leftInRound,
                name: values.name,
                total_revenue: values.totalRevenue,
                monthly_revenue: values.monthlyRevenue,
                introd_to: values.introdTo,
                interest_from: values.interestFrom,
                passed_by: values.passedBy,
                passed_by_founder: values.passedByFounder,
                call_with: values.callWith,
                checks_from: values.checksFrom,
                total_raised: values.totalRaised,
              }),
            }
          );
          const data = await res.json();

          if (res.status === 201) {
            document.getElementById("success-header").innerText = data.message;
            document.getElementById("add-success").style.display = "flex";
            resetForm();
          } else {
            document.getElementById("add-fail").style.display = "flex";
            document.getElementById('fail-header').innerText = data.message
          }
        }}
      >
        {({ values }) => (
          <Form id="add-company" className="add-form flex-col">
            <h2>Add company</h2>
            <TextInput
              label="Company name"
              name="name"
              type="text"
              placeholder="Company name"
            />
            {/* founder select */}
            <SelectInput
              id="founder-input"
              label="Founder"
              name="founderId"
            >
              <option value="">Select a founder</option>
            </SelectInput>
            {/* sectors checkbox */}
            <MultiSelectInput
              id="sector-select"
              label="Sectors"
              multiple="multiple"
              name="sectors"
            >
            </MultiSelectInput>
            {/* program select */}
            <SelectInput label="Company program" name="program">
              <option value="">Select an option</option>
              <option value="Incubator">Incubator</option>
              <option value="Accelerator">Accelerator</option>
              <option value="Incubator (Remote)">Incubator (Remote)</option>
              <option value="UARF">UARF</option>
            </SelectInput>
            <DateInput label="Date founded" name="foundedDate" type="date" />
            {/* raising select */}
            <SelectInput label="Currently raising" name="raising">
              <option value="">Select an option</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </SelectInput>
            {values.raising && (
              <>
                <NumberInput
                  label="Total revenue ($, thousands)"
                  name="totalRevenue"
                  type="number"
                />
                <NumberInput
                  label="Monthly revenue ($, thousands)"
                  name="monthlyRevenue"
                  type="number"
                />
                <NumberInput
                  label="Raise amount ($, thousands)"
                  name="amount"
                  type="number"
                />
                <NumberInput
                  label="Pre $ Valuation ($, thousands)"
                  name="pre"
                  type="number"
                />
                <NumberInput
                  label="Left in round ($, thousands)"
                  name="leftInRound"
                  type="number"
                />
                <TextInput
                  label="Already introduced to"
                  name="introdTo"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Interest from"
                  name="interestFrom"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Passed by"
                  name="passedBy"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Passed by Founder"
                  name="passedByFounder"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Calls with"
                  name="callWith"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <TextInput
                  label="Checks from"
                  name="checksFrom"
                  type="text"
                  placeholder="Firm names e.g. firm1,firm2,...,firmN"
                />
                <NumberInput
                  label="Total raised"
                  name="totalRaised"
                  type="number"
                />
              </>
            )}
            <button type="submit">Add company</button>
            <div className="clear-cancel-buttons flex">
              <button type="reset" onClick={(e) => Formik.resetForm()}>
                Clear
              </button>
              <button
                type="button"
                className="back-button"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="popup flex-col" id="add-success">
        <button className="hide-popup" onClick={hideSuccess}>
          X
        </button>
        <h2 className="popup-header" id="success-header">Successfully added</h2>
        <div className="popup-buttons flex">
          <Link className="popup-button" to="/tech/companies">
            See companies
          </Link>
          <button className="popup-button" onClick={hideSuccess}>
            Add another
          </button>
        </div>
      </div>
      <div className="popup flex-col" id="add-fail">
        <button className="hide-popup" onClick={hideFail}>
          X
        </button>
        <h2 className="popup-header" id="fail-header">Something went wrong, please try again</h2>
      </div>
    </div>
  );
};

export default AddCompanyForm;
