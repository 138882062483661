import "./FoundersPage.css"
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FoundersTable from "../../Components/Founders/FoundersTable/FoudnersTable"

const FoundersPage = () => {

  const [founders, setFounders] = useState(null);

  async function getData() {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/founders`, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    setFounders(data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (founders === null) {
    return <h2>Loading founders...</h2>
  } else {
    return (
      <div className="page-container flex-col">
        <div className="header-container">
          <h1 id="founders-header">Founders</h1>
        </div>
        <FoundersTable founders={founders} />
      </div>
    );
  }
};

export default FoundersPage