import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const FirmRow = ({ firm, index, prospect }) => {
  const [investor, setInvestor] = useState(null);

  async function getData() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/investors/${
      prospect ? `prospect/${firm.investor_id}` : `${firm.investor_id}`
    }`;
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    if (res.status === 200) {
      setInvestor(data);
    } else {
      setInvestor(undefined);
    }
  }

  useEffect(() => {
    if (firm.investor_id) {
      getData();
    } else {
      setInvestor(undefined);
    }
  }, [firm.investor_id]);

  function copyEmail () {
    navigator.clipboard.writeText(investor.email)
    alert('Email copied to clipboard')
  }

  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td>
        <Link to={`/tech/investors/${firm.id}`}>
          {firm.name}
          <span className="material-symbols-outlined">arrow_forward</span>
        </Link>
      </td>
      <td>{firm.relationship}</td>
      <td>
        {investor ? `${investor.first_name} ${investor.last_name}` : "N/A"}
      </td>
      <td>{investor && investor.email ? investor.email : "N/A"}{investor && investor.email ? <span className="material-symbols-outlined" id='copy-investor-email' onClick={copyEmail}>content_copy</span> : undefined}</td>
    </tr>
  );
};

export default FirmRow;
