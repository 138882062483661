import "./GrowHomePage.css";
import React from "react";
import { Link } from "react-router-dom";

const GrowHomePage = () => {
  return (
    <div className="grow-home-page flex">
      <div className="links-grid">
        <Link className="grow-home-link flex" to="/grow/ae">
          AE
        </Link>
        <Link className="grow-home-link flex" to="/grow/mortar">
          MORTAR
        </Link>
        <Link className="grow-home-link flex" to="/grow/next-level">
          Next Level
        </Link>
      </div>
    </div>
  );
};

export default GrowHomePage;
