import "./LogoutPage.css";
import React from "react";
import Cookies from "js-cookie";

const LogoutPage = (props) => {
  const { setLoggedIn } = props;
  async function handleLogout() {
    const logoutRes = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/logout`,
      {
        method: "POST",
        credentials: "include",
      }
    );
    setLoggedIn(false);
    Cookies.remove("connect.sid");
    window.location.href = "/";
  }
  return (
    <div className="logout-page-container flex-col">
      <h1 id="logout-header">Log out</h1>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default LogoutPage;
