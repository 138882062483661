import './AdminPage.css'
import React from "react";
import { Link } from "react-router-dom";

const AdminPage = (props) => {
  const { admin } = props;

  if (admin) {
    return (
      <div className="admin-page-container flex">
        <div className="links-grid">
          <Link className="admin-page-link flex" to="/register">Add User</Link>
          <Link className="admin-page-link flex" to="/users/update">Update User</Link>
          <Link className='admin-page-link flex' to='/users/delete'>Remove User</Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="admin-page-unauthorized-container flex-col">
        <h1>You don't have permission to access this page</h1>
        <Link to="/">Home</Link>
      </div>
    );
  }
};

export default AdminPage;
