import "./ContactCard.css";

export default function ContactCard({ header, contact }) {
  function copyEmail() {
    navigator.clipboard.writeText(contact.email);
    alert("Email copied");
  }
  return (
    <div className="contact-card flex-col">
      <h3 className="contact">{header}</h3>
      <div className="text-box flex-col">
        {!contact && <p>Not set</p>}
        {contact && contact.first_name && (
          <p className="contact-detail">
            {contact.first_name} {contact.last_name}
          </p>
        )}
        {contact && contact.title && (
          <p className="contact-detail">{contact.title}</p>
        )}
        {contact && contact.email && (
          <p className="contact-detail copy" onClick={copyEmail}>
            {contact.email}
            <span className="material-symbols-outlined">content_copy</span>
          </p>
        )}
        {contact && contact.phone && (
          <p className="contact-detail">{contact.phone}</p>
        )}
      </div>
    </div>
  );
}
